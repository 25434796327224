<header class="slider-main">
  <div
    id="carouselExampleIndicators"
    class="carousel slide carousel-fade"
    data-ride="carousel"
  >
    <ol class="carousel-indicators">
      <li
        data-target="#carouselExampleIndicators"
        data-slide-to="0"
        class="active"
      ></li>
      <li data-target="#carouselExampleIndicators" data-slide-to="1"></li>
      <li data-target="#carouselExampleIndicators" data-slide-to="2"></li>
    </ol>
    <div class="carousel-inner" role="listbox">
      <!-- Slide One - Set the background image for this slide in the line below -->
      <div
        class="carousel-item active"
        style="background-image: url('assets/img/courtesy/slider-2.jpg')"
      >
        <div class="carousel-caption d-none d-md-block">
          <h3>Welcome to Courtesy</h3>
          <p>Where there's healing and Courtesy, there's hope</p>
        </div>
      </div>
      <!-- Slide Two - Set the background image for this slide in the line below -->
      <div
        class="carousel-item"
        style="background-image: url('assets/img/courtesy/slider-3.jpg')"
      >
        <div class="carousel-caption d-none d-md-block">
          <h3>Welcome to Courtesy</h3>
          <p>Where there's healing and Courtesy, there's hope</p>
        </div>
      </div>
      <!-- Slide Three - Set the background image for this slide in the line below -->
      <div
        class="carousel-item"
        style="background-image: url('assets/img/courtesy/Home_Page.jpg')"
      >
        <div class="carousel-caption d-none d-md-block">
          <h3>Welcome to Courtesy</h3>
          <p>Where there's healing and Courtesy, there's hope</p>
        </div>
      </div>
    </div>
    <a
      class="carousel-control-prev"
      href="#carouselExampleIndicators"
      role="button"
      data-slide="prev"
    >
      <span class="carousel-control-prev-icon" aria-hidden="true"></span>
      <span class="sr-only">Previous</span>
    </a>
    <a
      class="carousel-control-next"
      href="#carouselExampleIndicators"
      role="button"
      data-slide="next"
    >
      <span class="carousel-control-next-icon" aria-hidden="true"></span>
      <span class="sr-only">Next</span>
    </a>
  </div>
</header>
<body>
  <div class="services-bar">
    <div class="container">
      <h1 class="py-4">Who We Are..</h1>
      <!-- Services Section -->
      <div class="row">
        <div class="col-lg-4 mb-4">
          <div class="card h-100">
            <div class="card-img">
              <img
                class="img-fluid"
                src="assets/img/courtesy/CHS_homecare_profesional.jpg"
                alt=""
              />
            </div>
            <div class="card-body">
              <h4 class="card-header">Home Care Professionals</h4>
              <p class="card-text">
                The service side of the agency’s operations is staffed by
                well-trained individuals who have worked in the healthcare
                industry for many years. They include nurses (Registered Nurses
                and Licensed Practical Nurses), nurse aides, home health aides,
                homemakers, social workers, and Clinicians.
              </p>
            </div>
          </div>
        </div>
        <div class="col-lg-4 mb-4">
          <div class="card h-100">
            <div class="card-img">
              <img
                class="img-fluid"
                src="assets/img/courtesy/CHS_our_team.jpg"
                alt=""
              />
            </div>
            <div class="card-body">
              <h4 class="card-header">Team of Management Professionals</h4>
              <p class="card-text">
                The management side of the agency’s operations is staffed by
                trained management professionals. Together, the CHS Team works
                seamlessly to create a one source service platform for our care
                recipients, making their care management less cumbersome.
              </p>
            </div>
          </div>
        </div>
        <div class="col-lg-4 mb-4">
          <div class="card h-100">
            <div class="card-img">
              <img
                class="img-fluid"
                src="assets/img/courtesy/CHS_Upholding_our_client.jpg"
                alt=""
              />
            </div>
            <div class="card-body">
              <h4 class="card-header">Upholding our Client's Trust in us</h4>
              <p class="card-text">
                We understand that decisions about health care options require
                thoughtful consideration. We also realize that trust plays a
                major role. For this reason, we ensure that trust is one of the
                key elements of the quality of care and services that we provide
                to everyone who is placed in our care.
              </p>
            </div>
          </div>
        </div>
      </div>
      <!-- /.row -->
    </div>
  </div>

  <!--Parallax Section-->
  <div
    class="parallax-banner parallax100"
    style="background-image: url(assets/img/courtesy/banner_2.jpeg);
   text-align: center; padding-top: 135px; padding-bottom: 158px;"
  >
    <span class="tit4">
      <a
        class="btn btn-lg btn-secondary btn-block parallax-contact"
        [routerLink]="['/contact']"
      >
        Hire Us
      </a>
    </span>
  </div>
  <!--End of Parallax-->

  <!--Start of Licenses-->

  <div class="container">
    <!-- About Section -->
    <div class="about-main">
      <div class="row">
        <div class="col-lg-6">
          <h2>Our Licenses and Certification</h2>
          <div class="row">
            <div class="col-lg-6">
              <ul>
                <li><h4>Private Duty Nursing</h4></li>
                <p>
                  At Courtesy Healthcare Services, we are proud to have a team
                  of nurses who can competently provide you with the care and
                  attention you expect to receive from nursing care
                  professionals.
                </p>
              </ul>
            </div>
            <div class="col-lg-6">
              <ul>
                <li><h4>Long and Short-term Live-in Care</h4></li>
                <P>
                  At Courtesy Healthcare Services, we offer Private Duty Care
                  for patients who need live-in care services.
                </P>
              </ul>
            </div>
          </div>
          <div class="row">
            <div class="col-lg-6">
              <ul>
                <li><h4>Companionship Services</h4></li>
                <p>
                  Keep your choice to stay at home. You can still remain in good
                  health with the help of Private Duty Care professionals from
                  Courtesy Healthcare Services.
                </p>
              </ul>
            </div>
            <div class="col-lg-6">
              <ul>
                <li><h4>Home Health Aide Services (HHA)</h4></li>
                <p>
                  Home Health Aides at Courtesy Healthcare Services are
                  licensed, bonded and insured – ready to be assigned and care
                  for you at home.
                </p>
              </ul>
            </div>
          </div>
        </div>
        <div class="col-lg-6">
          <img
            class="img-fluid rounded"
            src="assets/img/courtesy/nursing_certificate_opaque.png"
            alt=""
          />
        </div>
      </div>
      <!-- /.row -->
    </div>
  </div>

  <!--End of Licenses-->

  <!--Parallax Section-->
  <div
    class="parallax-banner parallax100"
    style="background-image: url(assets/img/courtesy/banner_1.jpg);
   text-align: center; padding-top: 135px; padding-bottom: 158px;"
  >
    <span class="tit4">
      <a
        class="btn btn-lg btn-secondary btn-block parallax-refer"
        [routerLink]="['/referrals']"
      >
        Refer Us
      </a>
    </span>
  </div>
  <!--End of Parallax-->

  <!--Services Sections-->
  <div class="container">
    <!-- Portfolio Section -->
    <div class="portfolio-main">
      <h2>Our Services</h2>
      <p style="text-align: center;">
        Whether you need help for a short time after you come home from a
        hospital stay or you need assistance in doing some of the tasks of daily
        living, Courtesy Healthcare Services is here for you and your family.
        We’re ready to listen to what you need and to talk with you about the
        many services and care we can provide. If you are looking for people you
        can trust, please call us first. When it matters most, count on us.
      </p>
      <div id="projects" class="projects-main row">
        <div class="col-lg-4 col-sm-6 pro-item portfolio-item business">
          <div class="card h-100">
            <div class="card-img">
              <a [routerLink]="['/personal-care']" data-fancybox="images">
                <img
                  class="card-img-top"
                  src="assets/img/courtesy/private_duty.jpg"
                  alt=""
                />
                <div class="overlay"><i class="fas fa-arrows-alt"></i></div>
              </a>
            </div>
            <div class="card-body">
              <h4 class="card-title">
                <a [routerLink]="['/personal-care']">Personal Care</a>
              </h4>
            </div>
          </div>
          <h5 style="text-align: center;">
            Personal Care
            <a
              [routerLink]="['/personal-care']"
              style="font-size: 14px; color: #8c54a1"
            >
              Read More..
            </a>
          </h5>
        </div>
        <div class="col-lg-4 col-sm-6 pro-item portfolio-item business travel">
          <div class="card h-100">
            <div class="card-img">
              <a [routerLink]="['/skilled-care']" data-fancybox="images">
                <img
                  class="card-img-top"
                  src="assets/img/courtesy/medical_equipment.png"
                  alt=""
                />
                <div class="overlay"><i class="fas fa-arrows-alt"></i></div>
              </a>
            </div>
            <div class="card-body">
              <h4 class="card-title">
                <a [routerLink]="['/skilled-care']">Skilled Care</a>
              </h4>
            </div>
          </div>
          <h5 style="text-align: center;">
            Skilled Care
            <a
              [routerLink]="['/skilled-care']"
              style="font-size: 14px; color: #8c54a1"
            >
              Read More..
            </a>
          </h5>
        </div>
        <div
          class="col-lg-4 col-sm-6 pro-item portfolio-item financial academic"
        >
          <div class="card h-100">
            <div class="card-img">
              <a [routerLink]="['/medical-equipments']" data-fancybox="images">
                <img
                  class="card-img-top"
                  src="assets/img/courtesy/med.jpg"
                  alt=""
                />
                <div class="overlay"><i class="fas fa-arrows-alt"></i></div>
              </a>
            </div>
            <div class="card-body">
              <h4 class="card-title">
                <a [routerLink]="['/medical-equipments']"
                  >Durable Medical Equipment</a
                >
              </h4>
            </div>
          </div>
          <h5 style="text-align: center;">
            Durable Medical Equipment
            <a
              [routerLink]="['/medical-equipments']"
              style="font-size: 14px; color: #8c54a1"
            >
              Read More..
            </a>
          </h5>
        </div>
      </div>
      <!-- /.row -->
    </div>
  </div>

  <!--End of Services Sections-->

  <div
    class="parallax-banner parallax100"
    style="background-image: url(assets/img/courtesy/banner_4.jpeg);
   text-align: center; padding-top: 135px; padding-bottom: 158px;"
  >
    <span class="tit4">
      <a
        class="btn btn-lg btn-secondary btn-block parallax-career"
        [routerLink]="['/careers']"
      >
        Come Join Us
      </a>
    </span>
  </div>

  <div class="portfolio-col" style="margin-top: 5%; text-align: center;">
    <h2 *ngIf="testimonials.length > 0">Testimonials</h2>
    <div class="container">
      <div class="row">
        <div
          class="col-lg-3 col-md-4 col-sm-6 portfolio-item"
          *ngFor="let item of testimonials"
        >
          <div class="card h-100">
            <div class="card-body">
              <h4 style="font-size: 16px;">{{ item.feedback }}</h4>
              <p>{{ item.clientName }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- /.container -->
  </div>
</body>
