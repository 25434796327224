<div class="full-title">
  <div class="container">
    <!-- Page Heading/Breadcrumbs -->
    <h1 class="mt-4 mb-3">Our Process</h1>
    <div class="breadcrumb-main">
      <ol class="breadcrumb">
        <li class="breadcrumb-item">
          <a href="index.html">Home</a>
        </li>
        <li class="breadcrumb-item active">Our Process</li>
      </ol>
    </div>
  </div>
</div>
<!-- End banner Area -->

<!-- Start About Us Area -->
<div class="container">
  <!-- About Section -->
  <div class="about-main">
    <section>
      <div class="row">
        <div class="col-lg-6">
          <h2>Welcome to Courtesy</h2>
          <h5>The Intake Call and Care plan</h5>
          <p>
            From the initial intake call, our team begins to gather vital
            information about your needs for home care. First, we ask questions
            to get an overall picture of your situation including contact
            information, living conditions, current medications, mobility
            issues, illnesses or diagnoses. This helps us determine the level of
            care you need and your in-home healthcare plan. We will also counsel
            you on the available state programs that you might qualify for to
            support your care plan from a financial perspective.
          </p>
        </div>
        <div class="col-lg-6">
          <img
            class="img-fluid rounded"
            src="assets/img/courtesy/companionship_1.jpg"
            alt=""
          />
        </div>
      </div>
    </section>
    <section style="margin-top: 5%;">
      <div class="row">
        <div class="col-lg-6">
          <img
            class="img-fluid rounded"
            src="assets/img/courtesy/CHS2.jpg"
            alt=""
          />
        </div>
        <div class="col-lg-6">
          <h5>Nursing Assesments</h5>
          <p>
            When medically necessary, we request a nursing assessment.
            Typically, this is done in the home with family members. The
            assessment is used to evaluate the medical condition of the patient
            and includes detailed recommendations that become our blueprint for
            the day-to-day care plan.
          </p>
        </div>
      </div>
    </section>
    <section style="margin-top: 5%;">
      <div class="row">
        <div class="col-lg-6">
          <h5>Making the Right Caregiver Match</h5>
          <p>
            Once you approve the care plan, we begin our assessment to match you
            or your loved one with the right caregiver. We take multiple factors
            into consideration, matching personality types to assigning male
            aides when heavy lifting is required. We look for caregivers with
            similar interests and we staff foreign language assignments when
            possible. And, we guarantee our matchmaking abilities. If you or
            your family is not comfortable with the fit, we change your
            caregiver immediately for one more suitable to your needs and
            comfort level.
          </p>
        </div>
        <div class="col-lg-6">
          <img
            class="img-fluid rounded"
            src="assets/img/courtesy/CHS1.jpg"
            alt=""
          />
        </div>
      </div>
    </section>
    <section style="margin-top: 5%;">
      <div class="row">
        <div class="col-lg-6">
          <img
            class="img-fluid rounded"
            src="assets/img/courtesy/g2.jpeg"
            alt=""
          />
        </div>
        <div class="col-lg-6">
          <h5>Ongoing Supervision and Communication</h5>
          <p>
            Courtesy Healthcare Services provides routine field supervision as
            well as random inspections to ensure safe, competent care and to
            stress the importance of punctuality, reliability and quality
            in-home care services. Many of our caregivers chronicle the care
            they provide to ensure that all duties are performed and all home
            care needs are met. Care notes can include general observations and
            services delivered.
          </p>
        </div>
      </div>
    </section>
    <section style="margin-top: 5%;">
      <div class="row">
        <div class="col-md-12" style="text-align: center;">
          <h5>Insurance Accepted</h5>
          <p>
            At Courtesy Healthcare Services; we want to offer our services to
            you conveniently. We accept various payment and major insurances.
            <br />
            Here is a list of the insurances we accept:
          </p>
        </div>
        <div class="col-md-3" style="text-align: center;">
          <span> <b> Private Pay </b> </span>
        </div>
        <div class="col-md-3" style="text-align: center;">
          <span> <b> Medicaid </b> </span>
        </div>
        <div class="col-md-3" style="text-align: center;">
          <span> <b> Medicare </b> </span>
        </div>
        <div class="col-md-3" style="text-align: center;">
          <span> and more... </span>
        </div>
      </div>
      <div class="row" style="margin-top: 6%;">
        <div class="col-md-3">
          <img
            class="img-fluid rounded"
            src="assets/img/courtesy/I1.png"
            alt=""
            style="width: 50%; height: 100px; margin: 20%;"
          />
        </div>
        <div class="col-md-3">
          <img
            class="img-fluid rounded"
            src="assets/img/courtesy/I2.jpg"
            alt=""
            style="width: 50%; height: 100px; margin: 20%;"
          />
        </div>
        <div class="col-md-3">
          <img
            class="img-fluid rounded"
            src="assets/img/courtesy/I3.png"
            alt=""
            style="width: 50%; height: 100px; margin: 20%;"
          />
        </div>
        <div class="col-md-3">
          <img
            class="img-fluid rounded"
            src="assets/img/courtesy/I4.jpg"
            alt=""
            style="width: 50%; height: 100px; margin: 20%;"
          />
        </div>
      </div>
      <div class="row" style="margin-top: 2%;">
        <div class="col-md-3">
          <img
            class="img-fluid rounded"
            src="assets/img/courtesy/I5.jpg"
            alt=""
            style="width: 50%; height: 100px; margin: 20%;"
          />
        </div>
        <div class="col-md-3">
          <img
            class="img-fluid rounded"
            src="assets/img/courtesy/I6.png"
            alt=""
            style="width: 50%; height: 100px; margin: 20%;"
          />
        </div>
        <div class="col-md-3">
          <img
            class="img-fluid rounded"
            src="assets/img/courtesy/optima.png"
            alt=""
            style="width: 50%; height: 100px; margin: 20%;"
          />
        </div>
        <div class="col-md-3">
          <img
            class="img-fluid rounded"
            src="assets/img/courtesy/private_insurance.jpg"
            alt=""
            style="width: 50%; height: 100px; margin: 20%;"
          />
        </div>
      </div>
    </section>
    <!-- /.row -->
  </div>
</div>

<!-- End About Us Area -->
