import { Component, OnInit } from '@angular/core';
import { ApiService } from '../api.service';
import { Router } from '@angular/router';
import { first } from 'rxjs/operators';

@Component({
  selector: 'app-faq',
  templateUrl: './faq.component.html',
  styleUrls: ['./faq.component.css']
})
export class FaqComponent implements OnInit {
  faq = [];

  constructor(private dataService: ApiService, private router: Router) {}

  ngOnInit() {
    this.dataService
      .getFAQ()
      .pipe(first())
      .subscribe(data => {
        this.faq = data;
      });
  }
}
