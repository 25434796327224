<div class="top-bar">
  <div class="container">
    <div class="row">
      <div class="col-lg-6">
        <div class="social-media">
          <ul>
            <li>
              <a
                href="https://www.facebook.com/courtesy.healthcare.9"
                target="_blank"
                ><i class="fab fa-facebook-f"></i
              ></a>
            </li>
            <li>
              <a href="https://twitter.com/CourtesyHs" target="_blank"
                ><i class="fab fa-twitter"></i
              ></a>
            </li>
            <li>
              <a
                href="https://www.linkedin.com/in/courtesy-healthcare-service/"
                target="_blank"
                ><i class="fab fa-linkedin-in"></i
              ></a>
            </li>
            <li>
              <a
                href="https://www.instagram.com/courtesyhealthcareservices/"
                target="_blank"
                ><i class="fab fa-instagram"></i
              ></a>
            </li>
          </ul>
        </div>
      </div>
      <div class="col-lg-6">
        <div class="contact-details">
          <ul>
            <li><i class="fas fa-phone fa-rotate-90"></i> (571) 428-2398</li>
            <li>
              <i class="fas fa-map-marker-alt"></i> Operating in Virginia and
              Maryland
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</div>
<nav class="navbar navbar-expand-lg navbar-dark bg-light top-nav">
  <div class="container">
    <div>
      <div style="float: left;">
        <a class="navbar-brand" href="index.html">
          <img src="assets/img/logo_1.png" alt="logo" />
        </a>
      </div>
      <div style="float: left;">
        <h3
          style="color: #8c54a1; font-size: 17px; margin-top: 5%; margin-left: 5%;"
        >
          Courtesy
        </h3>
        <h4 style="color: #8c54a1; font-size: 12px;">
          Healthcare Services
        </h4>
      </div>
      <div style="clear: both;"></div>
    </div>
    <button
      class="navbar-toggler navbar-toggler-right"
      type="button"
      data-toggle="collapse"
      data-target="#navbarResponsive"
      aria-controls="navbarResponsive"
      aria-expanded="false"
      aria-label="Toggle navigation"
    >
      <span class="fas fa-bars"></span>
    </button>
    <div class="collapse navbar-collapse" id="navbarResponsive">
      <ul class="navbar-nav ml-auto">
        <li class="nav-item">
          <a class="nav-link" href="index.html">Home</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" [routerLink]="['/covid']">Covid-19</a>
        </li>
        <li class="nav-item dropdown">
          <a
            class="nav-link"
            href="#"
            id="navbarDropdownBlog"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          >
            Why Us <i class="fas fa-sort-down"></i>
          </a>
          <div
            class="dropdown-menu dropdown-menu-right"
            aria-labelledby="navbarDropdownBlog"
          >
            <a class="dropdown-item" [routerLink]="['/about']">About Us</a>
            <a class="dropdown-item" [routerLink]="['/our-process']"
              >Our Process</a
            >
          </div>
        </li>
        <li class="nav-item dropdown">
          <a
            class="nav-link"
            href="#"
            id="navbarDropdownBlog"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          >
            Our Services <i class="fas fa-sort-down"></i>
          </a>
          <div
            class="dropdown-menu dropdown-menu-right"
            aria-labelledby="navbarDropdownBlog"
          >
            <a class="dropdown-item" [routerLink]="['/personal-care']"
              >Personal Care</a
            >
            <a class="dropdown-item" [routerLink]="['/skilled-care']"
              >Skilled Care</a
            >
            <a class="dropdown-item" [routerLink]="['/medical-equipments']"
              >Medical Equipments</a
            >
          </div>
        </li>
        <li class="nav-item dropdown">
          <a
            class="nav-link"
            href="#"
            id="navbarDropdownBlog"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          >
            Contact Us <i class="fas fa-sort-down"></i>
          </a>
          <div
            class="dropdown-menu dropdown-menu-right"
            aria-labelledby="navbarDropdownBlog"
          >
            <a class="dropdown-item" [routerLink]="['/contact']">Contact</a>
            <a class="dropdown-item" [routerLink]="['/careers']">Careers</a>
            <a class="dropdown-item" [routerLink]="['/referrals']">Referrals</a>
            <a class="dropdown-item" [routerLink]="['/faq']">FAQ</a>
          </div>
        </li>
        <li class="nav-item dropdown">
          <a
            class="nav-link"
            href="#"
            id="navbarDropdownBlog"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          >
            Search Details <i class="fas fa-sort-down"></i>
          </a>
          <div
            class="dropdown-menu dropdown-menu-right"
            aria-labelledby="navbarDropdownBlog"
          >
            <a class="dropdown-item" [routerLink]="['/patient-details']"
              >Patient Details</a
            >
            <a class="dropdown-item" [routerLink]="['/employee-details']"
              >Employee Details</a
            >
          </div>
        </li>
        <li class="nav-item">
          <a *ngIf="loginbtn" class="nav-link" [routerLink]="['/login']"
            >Login</a
          >
        </li>
        <li class="nav-item dropdown">
          <a
            class="nav-link"
            *ngIf="logoutbtn"
            href="#"
            id="navbarDropdownBlog"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          >
            Logged In <i class="fas fa-sort-down"></i>
          </a>
          <div
            class="dropdown-menu dropdown-menu-right"
            aria-labelledby="navbarDropdownBlog"
          >
            <a class="dropdown-item" (click)="logout()">Logout</a>
            <a
              class="dropdown-item"
              *ngIf="isUserAdmin"
              [routerLink]="['/admin']"
              >Go To Admin Portal</a
            >
          </div>
        </li>
      </ul>
    </div>
  </div>
</nav>
