<div class="full-title">
  <div class="container">
    <!-- Page Heading/Breadcrumbs -->
    <h1 class="mt-4 mb-3">Career</h1>
    <div class="breadcrumb-main">
      <ol class="breadcrumb">
        <li class="breadcrumb-item">
          <a href="index.html">Home</a>
        </li>
        <li class="breadcrumb-item active">Career</li>
      </ol>
    </div>
  </div>
</div>

<!-- Start Career Area -->

<section>
  <div class="row">
    <div class="col-md-6 col-sm-12">
      <img
        src="assets/img/courtesy/career.png"
        width="400px"
        height="300px"
        style="margin-top:5%; float:right;"
      />
    </div>
    <div
      class="col-md-6 col-sm-12"
      style="padding-left: 15%; padding-right: 15%;"
    >
      <h3 style="margin-top : 5%;">Careers</h3>
      <br />
      <span>
        You will get more compensation, more flexibility and more opportunities
        with employment with Courtesy Healthcare Services.
        <br />
        <br />
        <b>Always in demand:</b>
        <ul>
          <li>Registered Nurses</li>
          <li>Home Health Aides</li>
          <li>Physical Therapists</li>
          <li>Occupational Therapists</li>
          <li>Speech Therapists</li>
          <li>Personal Care Workers</li>
        </ul>
      </span>
    </div>
  </div>
</section>

<div class="row d-flex justify-content-center" style="margin-top: 3%;">
  <div class="col-md-10">
    <h3
      *ngIf="message"
      [style.background-color]="status == '1' ? '#42A02A' : '#E95A46'"
      style="color: white; text-align: center; font-size: 15px;"
    >
      {{ message }}
      <a
        style="float: right; font-size: 12px; color: black; text-align: center;
        margin-right: 2%; cursor: pointer;"
        (click)="closeMessage()"
      >
        X
      </a>
    </h3>
    <br />
    <h3 style="text-align: center;">Apply to Courtesy</h3>
    <div class="jumbotron">
      <form
        [formGroup]="careerForm"
        (ngSubmit)="insertCareer(careerForm)"
        autocomplete="off"
      >
        <div class="row">
          <div class="col-md-6 col-sm-12">
            <div class="form-group">
              <label for="firstname">First Name</label>
              <input
                type="text"
                name="firstname"
                formControlName="firstname"
                autocomplete="off"
                class="form-control input-sm"
                placeholder="First Name"
              />
            </div>
          </div>
          <div class="col-md-6 col-sm-12">
            <div class="form-group">
              <label for="lastname">Last Name</label>
              <input
                type="text"
                name="lastname"
                formControlName="lastname"
                autocomplete="off"
                class="form-control input-sm"
                placeholder="Last Name"
              />
            </div>
          </div>

          <div class="col-md-3 col-sm-12">
            <div class="form-group">
              <label for="ssn">SSN</label>
              <input
                type="text"
                name="ssn"
                formControlName="ssn"
                autocomplete="off"
                class="form-control input-sm"
                placeholder="SSN"
                (blur)="onBlurSSN($event.target.value)"
              />
            </div>
          </div>
          <div class="col-md-3 col-sm-12">
            <div class="form-group">
              <label for="contact">Phone Number</label>
              <input
                type="text"
                name="contact"
                formControlName="contact"
                autocomplete="off"
                class="form-control input-sm"
                placeholder="Number"
              />
            </div>
          </div>
          <div class="col-md-4 col-sm-12">
            <div class="form-group">
              <label for="email">Email</label>
              <input
                type="text"
                name="email"
                formControlName="email"
                autocomplete="off"
                class="form-control input-sm"
                placeholder="Email"
              />
            </div>
          </div>
          <div class="col-md-2 col-sm-12">
            <div class="form-group">
              <label for="position">Position Applying For</label>
              <select
                name="position"
                formControlName="position"
                class="form-control"
              >
                <option value="HHA"> HHA </option>
                <option value="CNA"> CNA </option>
                <option value="RN"> RN </option>
                <option value="LPN"> LPN </option>
              </select>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12 col-sm-12">
            <div class="form-group">
              <label for="address">Address</label>
              <input
                type="text"
                name="address"
                formControlName="address"
                autocomplete="off"
                class="form-control input-sm"
                placeholder="Address"
              />
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-4 col-sm-12">
            <div class="form-group">
              <label for="city">City</label>
              <input
                type="text"
                name="city"
                formControlName="city"
                autocomplete="off"
                class="form-control input-sm"
                placeholder="City"
              />
            </div>
          </div>
          <div class="col-md-4 col-sm-12">
            <div class="form-group">
              <label for="state">State/Province</label>
              <input
                type="text"
                name="state"
                formControlName="state"
                autocomplete="off"
                class="form-control input-sm"
                placeholder="State/Province"
              />
            </div>
          </div>
          <div class="col-md-4 col-sm-12">
            <div class="form-group">
              <label for="zip">Zip/Postal Code</label>
              <input
                type="text"
                name="zip"
                formControlName="zip"
                autocomplete="off"
                class="form-control input-sm"
                placeholder="Zip/Postal Code"
              />
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6 col-sm-12">
            <label for="qualification">Qualification</label>
            <textarea
              name="qualification"
              formControlName="qualification"
              autocomplete="off"
              class="form-control"
              placeholder="Enter Qualification"
              rows="5"
            ></textarea>
          </div>
          <div class="col-md-6 col-sm-12">
            <label for="experience">Work Experience</label>
            <textarea
              name="experience"
              formControlName="experience"
              autocomplete="off"
              class="form-control"
              placeholder="Enter Experience"
              rows="5"
            ></textarea>
          </div>
        </div>
        <button type="submit" class="btn btn-success" style="margin-top: 4%;">
          Submit
        </button>
      </form>
    </div>
  </div>
</div>
