import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ApiService } from '../api.service';
import { Router } from '@angular/router';
import { first } from 'rxjs/operators';
import { Employee, Patient, ReferPatient, ReferNurse } from '../users';

@Component({
  selector: 'app-referrals',
  templateUrl: './referrals.component.html',
  styleUrls: ['./referrals.component.css']
})
export class ReferralsComponent implements OnInit {
  formType = {
    employee: false,
    patient: true
  };
  message: string;
  status: string;
  employeeData: ReferNurse;
  patientData: ReferPatient;

  servicesList = [];
  selectedServices = [];
  dropdownSettings: any = {};

  employeeReferralForm: FormGroup;
  patientReferralForm: FormGroup;

  constructor(
    private fb: FormBuilder,
    private dataService: ApiService,
    private router: Router
  ) {}

  ngOnInit() {
    this.employeeReferralForm = this.fb.group({
      firstname: [''],
      lastname: [''],
      contact: [''],
      email: [''],
      address: [''],
      city: [''],
      state: [''],
      zip: [''],
      qualification: [''],
      experience: ['']
    });

    this.patientReferralForm = this.fb.group({
      firstname: [''],
      lastname: [''],
      age: [''],
      contact: [''],
      email: [''],
      address: [''],
      city: [''],
      state: [''],
      zip: [''],
      diagnoses: [''],
      services: ['']
    });
  }

  insertEmployeeRefrerral(employeeReferralForm) {
    this.employeeData = {
      firstname: employeeReferralForm.value.firstname,
      lastname: employeeReferralForm.value.lastname,
      contact: employeeReferralForm.value.contact,
      email: employeeReferralForm.value.email,
      address: employeeReferralForm.value.address,
      city: employeeReferralForm.value.city,
      state: employeeReferralForm.value.state,
      zip: employeeReferralForm.value.zip,
      qualification: employeeReferralForm.value.qualification,
      experience: employeeReferralForm.value.experience
    };

    this.dataService
      .addNurseReferral(this.employeeData)
      .pipe(first())
      .subscribe(
        data => {
          const redirect = this.dataService.redirectUrl
            ? this.dataService.redirectUrl
            : '/referrals';
          this.router.navigate([redirect]);
          this.employeeReferralForm.reset();
          this.status = data.success;
          this.message = 'Submitted Successfully.';
        },
        error => {
          this.router.navigate(['/referrals']);
          this.employeeReferralForm.reset();
          this.message = 'Some Error Occurred.';
        }
      );
  }

  insertPatientReferral(patientReferralForm) {
    this.patientData = {
      firstname: patientReferralForm.value.firstname,
      lastname: patientReferralForm.value.lastname,
      age: patientReferralForm.value.age,
      contact: patientReferralForm.value.contact,
      email: patientReferralForm.value.email,
      address: patientReferralForm.value.address,
      city: patientReferralForm.value.city,
      state: patientReferralForm.value.state,
      zip: patientReferralForm.value.zip,
      diagnoses: patientReferralForm.value.diagnoses,
      services: patientReferralForm.value.services
    };

    this.dataService
      .addPatientReferral(this.patientData)
      .pipe(first())
      .subscribe(
        data => {
          const redirect = this.dataService.redirectUrl
            ? this.dataService.redirectUrl
            : '/referrals';
          this.router.navigate([redirect]);
          this.patientReferralForm.reset();
          this.status = data.success;
          this.message = 'Submitted Successfully.';
        },
        error => {
          this.router.navigate(['/referrals']);
          this.patientReferralForm.reset();
          this.message = 'Some Error Occurred.';
        }
      );
  }

  closeMessage() {
    this.message = null;
  }

  createForm(formName) {
    this.formType['employee'] = false;
    this.formType['patient'] = false;
    this.formType[formName] = true;
  }
}
