<div class="full-title">
  <div class="container">
    <!-- Page Heading/Breadcrumbs -->
    <h1 class="mt-4 mb-3">Refer to Courtesy..!!</h1>
    <div class="breadcrumb-main">
      <ol class="breadcrumb">
        <li class="breadcrumb-item">
          <a href="index.html">Home</a>
        </li>
        <li class="breadcrumb-item active">Referral</li>
      </ol>
    </div>
  </div>
</div>
<!-- End banner Area -->

<!-- Start About Us Area -->
<section class="about-area section-gap">
  <div class="container">
    <div class="section-top-border">
      <h3 class="mb-30">Refer Now!</h3>
      <div class="row">
        <div class="col-lg-12">
          <blockquote class="generic-blockquote">
            Courtesy Healthcare Services welcomes referrals from social workers,
            discharge planners, elder law attorneys, financial advisors, clergy
            and other professionals who provide assistance to seniors. We are a
            licensed, bonded and Insured, non-skilled home health care agency.
            Our primary service coverage area is Northern Virginia (City of
            Alexandria, City of Arlington, and the counties of Fairfax, Loudoun
            and Prince William) <br />

            To refer a client for home care services, simply call, Fax or e-mail
            us the client’s name and phone number, along with your assessment of
            the level of care required. One of our experienced home-care
            coordinators will call you back to review your recommendation. Once
            arrangements have been made, we’ll give you a courtesy call to brief
            you on the home care services being provided to your client. <br />

            When you leave the details to us, you can rest easy knowing that
            your client is in good hands.
          </blockquote>
        </div>
      </div>
    </div>
    <div class="col-sm-12 col-md-12 d-flex justify-content-center">
      <div
        class="col-md-2 col-sm-6 d-flex justify-content-center"
        (click)="createForm('patient')"
      >
        <button type="button" class="btn btn-primary">Refer a Patient</button>
      </div>
      <div
        class="col-md-2 col-sm-6 d-flex justify-content-center"
        (click)="createForm('employee')"
      >
        <button type="button" class="btn btn-primary">
          Refer a Nurse
        </button>
      </div>
    </div>
    <div class="col-lg-12 col-md-12">
      <div
        class="row d-flex justify-content-center"
        *ngIf="formType.employee"
        style="margin-top: 5%;"
      >
        <div class="col-md-10">
          <h3
            *ngIf="message"
            [style.background-color]="status == '1' ? '#42A02A' : '#E95A46'"
            style="color: white; text-align: center; font-size: 15px;"
          >
            {{ message }}
            <a
              style="float: right; font-size: 12px; color: black; text-align: center;
              margin-right: 2%; cursor: pointer;"
              (click)="closeMessage()"
            >
              X
            </a>
          </h3>
          <br />
          <h3 style="text-align: center;">Refer a Nurse</h3>
          <div class="jumbotron" style="margin-top: 5%;">
            <form
              [formGroup]="employeeReferralForm"
              (ngSubmit)="insertEmployeeRefrerral(employeeReferralForm)"
              autocomplete="off"
            >
              <div class="row">
                <div class="col-md-6 col-sm-12">
                  <div class="form-group">
                    <label for="firstname">First Name</label>
                    <input
                      type="text"
                      name="firstname"
                      formControlName="firstname"
                      autocomplete="off"
                      class="form-control input-sm"
                      placeholder="First Name"
                    />
                  </div>
                </div>
                <div class="col-md-6 col-sm-12">
                  <div class="form-group">
                    <label for="lastname">Last Name</label>
                    <input
                      type="text"
                      name="lastname"
                      formControlName="lastname"
                      autocomplete="off"
                      class="form-control input-sm"
                      placeholder="Last Name"
                    />
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-md-5 col-sm-12">
                  <div class="form-group">
                    <label for="contact">Phone Number</label>
                    <input
                      type="text"
                      name="contact"
                      formControlName="contact"
                      autocomplete="off"
                      class="form-control input-sm"
                      placeholder="Number"
                    />
                  </div>
                </div>
                <div class="col-md-7 col-sm-12">
                  <div class="form-group">
                    <label for="email">Email</label>
                    <input
                      type="text"
                      name="email"
                      formControlName="email"
                      autocomplete="off"
                      class="form-control input-sm"
                      placeholder="Email"
                    />
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-md-12 col-sm-12">
                  <div class="form-group">
                    <label for="address">Address</label>
                    <input
                      type="text"
                      name="address"
                      formControlName="address"
                      autocomplete="off"
                      class="form-control input-sm"
                      placeholder="Address"
                    />
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-md-4 col-sm-12">
                  <div class="form-group">
                    <label for="city">City</label>
                    <input
                      type="text"
                      name="city"
                      formControlName="city"
                      autocomplete="off"
                      class="form-control input-sm"
                      placeholder="City"
                    />
                  </div>
                </div>
                <div class="col-md-4 col-sm-12">
                  <div class="form-group">
                    <label for="state">State/Province</label>
                    <input
                      type="text"
                      name="state"
                      formControlName="state"
                      autocomplete="off"
                      class="form-control input-sm"
                      placeholder="State/Province"
                    />
                  </div>
                </div>
                <div class="col-md-4 col-sm-12">
                  <div class="form-group">
                    <label for="zip">Zip/Postal Code</label>
                    <input
                      type="text"
                      name="zip"
                      formControlName="zip"
                      autocomplete="off"
                      class="form-control input-sm"
                      placeholder="Zip/Postal Code"
                    />
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-md-6 col-sm-12">
                  <label for="qualification">Qualification</label>
                  <textarea
                    name="qualification"
                    formControlName="qualification"
                    autocomplete="off"
                    class="form-control"
                    placeholder="Enter Qualification"
                    rows="5"
                  ></textarea>
                </div>
                <div class="col-md-6 col-sm-12">
                  <label for="experience">Work Experience</label>
                  <textarea
                    name="experience"
                    formControlName="experience"
                    autocomplete="off"
                    class="form-control"
                    placeholder="Enter Experience"
                    rows="5"
                  ></textarea>
                </div>
              </div>
              <button
                type="submit"
                class="btn btn-success"
                style="margin-top: 4%;"
              >
                Refer Nurse
              </button>
            </form>
          </div>
        </div>
      </div>

      <div
        class="row d-flex justify-content-center"
        *ngIf="formType.patient"
        style="margin-top: 5%;"
      >
        <div class="col-md-10">
          <h3
            *ngIf="message"
            [style.background-color]="status == '1' ? '#42A02A' : '#E95A46'"
            style="color: white; text-align: center; font-size: 15px;"
          >
            {{ message }}
            <a
              style="float: right; font-size: 12px; color: black; text-align: center;
              margin-right: 2%; cursor: pointer;"
              (click)="closeMessage()"
            >
              X
            </a>
          </h3>
          <br />
          <h3 style="text-align: center;">Refer a Patient</h3>
          <div class="jumbotron" style="margin-top: 5%;">
            <form
              [formGroup]="patientReferralForm"
              (ngSubmit)="insertPatientReferral(patientReferralForm)"
              autocomplete="off"
            >
              <div class="row">
                <div class="col-md-6 col-sm-12">
                  <div class="form-group">
                    <label for="firstname">First Name</label>
                    <input
                      type="text"
                      name="firstname"
                      formControlName="firstname"
                      autocomplete="off"
                      class="form-control input-sm"
                      placeholder="First Name"
                    />
                  </div>
                </div>
                <div class="col-md-6 col-sm-12">
                  <div class="form-group">
                    <label for="lastname">Last Name</label>
                    <input
                      type="text"
                      name="lastname"
                      formControlName="lastname"
                      autocomplete="off"
                      class="form-control input-sm"
                      placeholder="Last Name"
                    />
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-md-3 col-sm-12">
                  <div class="form-group">
                    <label for="age">Age</label>
                    <input
                      type="text"
                      name="age"
                      formControlName="age"
                      autocomplete="off"
                      class="form-control input-sm"
                      placeholder="Age"
                    />
                  </div>
                </div>
                <div class="col-md-4 col-sm-12">
                  <div class="form-group">
                    <label for="contact">Phone Number</label>
                    <input
                      type="text"
                      name="contact"
                      formControlName="contact"
                      autocomplete="off"
                      class="form-control input-sm"
                      placeholder="Number"
                    />
                  </div>
                </div>
                <div class="col-md-5 col-sm-12">
                  <div class="form-group">
                    <label for="email">Email</label>
                    <input
                      type="text"
                      name="email"
                      formControlName="email"
                      autocomplete="off"
                      class="form-control input-sm"
                      placeholder="Email"
                    />
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-md-12 col-sm-12">
                  <div class="form-group">
                    <label for="address">Address</label>
                    <input
                      type="text"
                      name="address"
                      formControlName="address"
                      autocomplete="off"
                      class="form-control input-sm"
                      placeholder="Address"
                    />
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-md-4 col-sm-12">
                  <div class="form-group">
                    <label for="city">City</label>
                    <input
                      type="text"
                      name="city"
                      formControlName="city"
                      autocomplete="off"
                      class="form-control input-sm"
                      placeholder="City"
                    />
                  </div>
                </div>
                <div class="col-md-4 col-sm-12">
                  <div class="form-group">
                    <label for="state">State/Province</label>
                    <input
                      type="text"
                      name="state"
                      formControlName="state"
                      autocomplete="off"
                      class="form-control input-sm"
                      placeholder="State/Province"
                    />
                  </div>
                </div>
                <div class="col-md-4 col-sm-12">
                  <div class="form-group">
                    <label for="zip">Zip/Postal Code</label>
                    <input
                      type="text"
                      name="zip"
                      formControlName="zip"
                      autocomplete="off"
                      class="form-control input-sm"
                      placeholder="Zip/Postal Code"
                    />
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-md-12 col-sm-12">
                  <label for="diagnoses">Diagnoses</label>
                  <textarea
                    name="diagnoses"
                    formControlName="diagnoses"
                    autocomplete="off"
                    class="form-control"
                    placeholder="Enter Diagnoses"
                    rows="5"
                  ></textarea>
                </div>
              </div>
              <div class="row">
                <div class="col-md-12 col-sm-12">
                  <label for="services">Services</label>
                  <input
                    type="text"
                    name="services"
                    formControlName="services"
                    autocomplete="off"
                    class="form-control input-sm"
                    placeholder="Services Required"
                  />
                </div>
              </div>
              <button
                type="submit"
                class="btn btn-success"
                style="margin-top: 4%;"
              >
                Refer a Patient
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<!-- End About Us Area -->
