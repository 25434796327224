export class LoginUser {
  public Id: number;
  public name: string;
  public pwd: string;

  constructor(Id: number, name: string, pwd: string) {
    this.Id = Id;
    this.name = name;
    this.pwd = pwd;
  }
}

export class Employee {
  public employeeID: number;
  public ssn: string;
  public role: string;
  public firstname: string;
  public lastname: string;
  public contact: number;
  public email: string;
  public address: string;
  public city: string;
  public state: string;
  public zip: number;
  public qualification: string;
  public experience: string;

  constructor(
    employeeID: number,
    ssn: string,
    role: string,
    firstname: string,
    lastname: string,
    contact: number,
    email: string,
    address: string,
    city: string,
    state: string,
    zip: number,
    qualification: string,
    experience: string
  ) {
    this.employeeID = employeeID;
    this.ssn = ssn;
    this.role = role;
    this.firstname = firstname;
    this.lastname = lastname;
    this.contact = contact;
    this.email = email;
    this.address = address;
    this.city = city;
    this.state = state;
    this.zip = zip;
    this.qualification = qualification;
    this.experience = experience;
  }
}

export class Patient {
  public patientID: number;
  public ssn: string;
  public firstname: string;
  public lastname: string;
  public age: string;
  public contact: number;
  public email: string;
  public emergencyContactName: string;
  public emergencyContactRelation: string;
  public emergencyContactNumber: string;
  public address: string;
  public city: string;
  public state: string;
  public zip: number;
  public diagnoses: string;
  public doctor: string;
  public nurseID: number;
  public services: string;
  public insuranceType: string;
  public privateInsurance: string;
  public history: string;

  constructor(
    patientID: number,
    ssn: string,
    firstname: string,
    lastname: string,
    age: string,
    contact: number,
    email: string,
    emergencyContactName: string,
    emergencyContactRelation: string,
    emergencyContactNumber: string,
    address: string,
    city: string,
    state: string,
    zip: number,
    diagnoses: string,
    doctor: string,
    nurseID: number,
    services: string,
    insuranceType: string,
    privateInsurance: string,
    history: string
  ) {
    this.patientID = patientID;
    this.ssn = ssn;
    this.firstname = firstname;
    this.lastname = lastname;
    this.age = age;
    this.contact = contact;
    this.email = email;
    this.emergencyContactName = emergencyContactName;
    this.emergencyContactRelation = emergencyContactRelation;
    this.emergencyContactNumber = emergencyContactNumber;
    this.address = address;
    this.city = city;
    this.state = state;
    this.zip = zip;
    this.diagnoses = diagnoses;
    this.doctor = doctor;
    this.nurseID = nurseID;
    this.services = services;
    this.insuranceType = insuranceType;
    this.privateInsurance = privateInsurance;
    this.history = history;
  }
}

export class Career {
  public ssn: string;
  public position: string;
  public firstname: string;
  public lastname: string;
  public contact: number;
  public email: string;
  public address: string;
  public city: string;
  public state: string;
  public zip: number;
  public qualification: string;
  public experience: string;

  constructor(
    ssn: string,
    position: string,
    firstname: string,
    lastname: string,
    contact: number,
    email: string,
    address: string,
    city: string,
    state: string,
    zip: number,
    qualification: string,
    experience: string
  ) {
    this.ssn = ssn;
    this.position = position;
    this.firstname = firstname;
    this.lastname = lastname;
    this.contact = contact;
    this.email = email;
    this.address = address;
    this.city = city;
    this.state = state;
    this.zip = zip;
    this.qualification = qualification;
    this.experience = experience;
  }
}

export class Contact {
  public firstname: string;
  public lastname: string;
  public contact: number;
  public email: string;
  public address: string;
  public city: string;
  public state: string;
  public zip: number;
  public comment: string;

  constructor(
    firstname: string,
    lastname: string,
    contact: number,
    email: string,
    address: string,
    city: string,
    state: string,
    zip: number,
    comment: string
  ) {
    this.firstname = firstname;
    this.lastname = lastname;
    this.contact = contact;
    this.email = email;
    this.address = address;
    this.city = city;
    this.state = state;
    this.zip = zip;
    this.comment = comment;
  }
}

export class ReferPatient {
  public firstname: string;
  public lastname: string;
  public age: string;
  public contact: number;
  public email: string;
  public address: string;
  public city: string;
  public state: string;
  public zip: number;
  public diagnoses: string;
  public services: string;

  constructor(
    firstname: string,
    lastname: string,
    age: string,
    contact: number,
    email: string,
    address: string,
    city: string,
    state: string,
    zip: number,
    diagnoses: string,
    services: string
  ) {
    this.firstname = firstname;
    this.lastname = lastname;
    this.age = age;
    this.contact = contact;
    this.email = email;
    this.address = address;
    this.city = city;
    this.state = state;
    this.zip = zip;
    this.diagnoses = diagnoses;
    this.services = services;
  }
}

export class ReferNurse {
  public firstname: string;
  public lastname: string;
  public contact: number;
  public email: string;
  public address: string;
  public city: string;
  public state: string;
  public zip: number;
  public qualification: string;
  public experience: string;

  constructor(
    firstname: string,
    lastname: string,
    contact: number,
    email: string,
    address: string,
    city: string,
    state: string,
    zip: number,
    qualification: string,
    experience: string
  ) {
    this.firstname = firstname;
    this.lastname = lastname;
    this.contact = contact;
    this.email = email;
    this.address = address;
    this.city = city;
    this.state = state;
    this.zip = zip;
    this.qualification = qualification;
    this.experience = experience;
  }
}
