<div class="full-title">
  <div class="container">
    <!-- Page Heading/Breadcrumbs -->
    <h1 class="mt-4 mb-3">Covid 19</h1>
    <div class="breadcrumb-main">
      <ol class="breadcrumb">
        <li class="breadcrumb-item">
          <a href="index.html">Home</a>
        </li>
        <li class="breadcrumb-item active">Covid 19</li>
      </ol>
    </div>
  </div>
</div>
<!-- End banner Area -->

<!-- Start About Us Area -->
<section class="about-area section-gap">
  <div class="container">
    <div class="row d-flex justify-content-center">
      <div class="col-lg-12">
        <div class="section-title-wrap text-center">
          <h1>Covid-19 Updates</h1>
          <p>CORONAVIRUS PANDEMIC</p>
        </div>
      </div>
    </div>
    <div id="target-div">
      <iframe
        src="https://coronavirus.jhu.edu/map.html"
        style="width: 100%; height: 1000px;"
      ></iframe>
    </div>
  </div>
</section>
<!-- End About Us Area -->
