import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './home/home.component';
import { AboutComponent } from './about/about.component';
import { FaqComponent } from './faq/faq.component';
import { CovidComponent } from './covid/covid.component';
import { CareersComponent } from './careers/careers.component';
import { ReferralsComponent } from './referrals/referrals.component';
import { ContactComponent } from './contact/contact.component';
import { HireUsComponent } from './hire-us/hire-us.component';
import { LoginComponent } from './login/login.component';
import { AdminComponent } from './admin/admin.component';
import { AuthguardGuard } from './authguard.guard';
import { PatientdetailsComponent } from './patientdetails/patientdetails.component';
import { EmployeedetailsComponent } from './employeedetails/employeedetails.component';
import { PrivateDutyComponent } from './private-duty/private-duty.component';
import { PersonalCareComponent } from './personal-care/personal-care.component';
import { CompanionshipComponent } from './companionship/companionship.component';
import { OurProcessComponent } from './our-process/our-process.component';
import { SkilledCareComponent } from './skilled-care/skilled-care.component';
import { MedicalEquipmentsComponent } from './medical-equipments/medical-equipments.component';

const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    redirectTo: '/home'
  },
  {
    path: 'home',
    component: HomeComponent
  },
  {
    path: 'covid',
    component: CovidComponent
  },
  {
    path: 'about',
    component: AboutComponent
  },
  {
    path: 'our-process',
    component: OurProcessComponent
  },
  {
    path: 'careers',
    component: CareersComponent
  },
  {
    path: 'referrals',
    component: ReferralsComponent
  },
  {
    path: 'faq',
    component: FaqComponent
  },
  {
    path: 'contact',
    component: ContactComponent
  },
  {
    path: 'patient-details',
    component: PatientdetailsComponent
  },
  {
    path: 'employee-details',
    component: EmployeedetailsComponent
  },
  {
    path: 'hire-us',
    component: HireUsComponent
  },
  {
    path: 'private-duty',
    component: PrivateDutyComponent
  },
  {
    path: 'personal-care',
    component: PersonalCareComponent
  },
  {
    path: 'companionship',
    component: CompanionshipComponent
  },
  {
    path: 'skilled-care',
    component: SkilledCareComponent
  },
  {
    path: 'medical-equipments',
    component: MedicalEquipmentsComponent
  },
  {
    path: 'login',
    component: LoginComponent
  },
  {
    path: 'admin',
    component: AdminComponent,
    canActivate: [AuthguardGuard]
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { scrollPositionRestoration: 'enabled' })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
