import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-personal-care',
  templateUrl: './personal-care.component.html',
  styleUrls: ['./personal-care.component.css']
})
export class PersonalCareComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
