import { Component, OnInit } from '@angular/core';
declare var $: any;

@Component({
  selector: 'app-covid',
  templateUrl: './covid.component.html',
  styleUrls: ['./covid.component.css']
})
export class CovidComponent implements OnInit {
  constructor() {}

  ngOnInit() {
    //$('#target-div').load('https://coronavirus.jhu.edu/map.html #ember6');
  }
}
