import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-medical-equipments',
  templateUrl: './medical-equipments.component.html',
  styleUrls: ['./medical-equipments.component.css']
})
export class MedicalEquipmentsComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
