<div class="full-title">
  <div class="container">
    <!-- Page Heading/Breadcrumbs -->
    <h1 class="mt-4 mb-3">Skilled Care</h1>
    <div class="breadcrumb-main">
      <ol class="breadcrumb">
        <li class="breadcrumb-item">
          <a href="index.html">Home</a>
        </li>
        <li class="breadcrumb-item active">Skilled Care</li>
      </ol>
    </div>
  </div>
</div>
<!-- End banner Area -->

<!-- Start Private Duty Area -->
<div class="item-pro">
  <div class="container">
    <!-- Portfolio Item Row -->
    <div class="row">
      <p style="text-align: center;">
        At Courtesy Healthcare Services, we are proud to have a team of nurses
        who can competently provide you with the care and attention you expect
        to receive from nursing care professionals. To ensure consistent
        services, we regularly conduct reviews on their performance and the
        level of responsiveness they maintain for patients. We also measure
        accuracy in following the care plan. Such processes enable us to
        identify gaps and point out necessary adjustments.
      </p>
    </div>
    <div class="row" style="margin-top: 2%;">
      <div class="col-md-8">
        <img class="img-fluid" src="assets/img/courtesy/CHS-PT.jpg" alt="" />
      </div>
      <div class="col-md-4">
        <h3 class="my-3">SKILLED NURSING</h3>
        <p>
          Courtesy Healthcare Services’ nurses perform a variety of specialized
          and highly skilled nursing services, coordinate the treatment plan
          with the other team members, and communicate with the patient’s
          physician and family. <br />
          <br />
          <b> Services may include: </b>
        </p>
        <ul>
          <li style="display: list-item;">
            <span>Assessment of condition</span>
          </li>
          <li style="display: list-item;"><span>Dressing changes</span></li>
          <li style="display: list-item;"><span>Wound care</span></li>
          <li style="display: list-item;"><span>Catheter care</span></li>
          <li style="display: list-item;"><span>Injections</span></li>
          <li style="display: list-item;"><span>I.V. Therapy</span></li>
          <li style="display: list-item;">
            <span>Patient education and pain management</span>
          </li>
          <li style="display: list-item;"><span>Post-surgical care</span></li>
        </ul>
      </div>
    </div>
    <div class="row" style="margin-top: 10%;">
      <div class="col-md-12" style="text-align: center;">
        <h3>Physical, Occupational, and Speech Therapies</h3>
        <p>
          Courtesy Healthcare Services’ therapists have experience with
          rehabilitation in the home and have degrees and credentials within
          their specialties. A full range of therapies including physical and
          occupational therapy, as well as speech pathology, are offered. Each
          therapist focuses on a different aspect of the patient’s
          rehabilitation.
        </p>
      </div>
    </div>
    <div class="row" style="margin-top: 5%;">
      <div class="col-md-6">
        <h3 class="my-3">PHYSICAL THERAPY</h3>
        <p>
          Upon evaluation, Courtesy Healthcare Services’ licensed physical
          therapists develop a treatment plan, in conjunction with the patient’s
          physician, for patients with various neurological, bone, or muscular
          disorders. <br />
          <br />
          <b> Services may include: </b>
        </p>
        <ul>
          <li style="display: list-item;">
            <span
              >Exercise programs for strength, endurance, coordination and
              balance</span
            >
          </li>
          <li style="display: list-item;">
            <span>Walking and transfer training</span>
          </li>
          <li style="display: list-item;">
            <span>Instruction in body mechanics and safety</span>
          </li>
          <li style="display: list-item;">
            <span
              >Family or caregiver training to reinforce the benefits of
              treatment</span
            >
          </li>
        </ul>
      </div>
      <div class="col-md-6">
        <img class="img-fluid" src="assets/img/courtesy/CHS_PT2.jpg" alt="" />
      </div>
    </div>
    <div class="row" style="margin-top: 5%;">
      <div class="col-md-6">
        <img class="img-fluid" src="assets/img/courtesy/CHS_OT.jpg" alt="" />
      </div>
      <div class="col-md-6">
        <h3 class="my-3">OCCUPATIONAL THERAPY</h3>
        <p>
          Patients needing to regain functional use of their arms and legs and
          increase their safety and independence in daily activities will
          benefit from the skills of Courtesy Healthcare Services’ registered
          occupational therapists. <br />
          <br />
          <b> Treatment may include: </b>
        </p>
        <ul>
          <li style="display: list-item;">
            <span>Evaluation of and fitting for adaptive equipment</span>
          </li>
          <li style="display: list-item;">
            <span
              >Instruction in energy conservation, work simplification and joint
              protection</span
            >
          </li>
          <li style="display: list-item;">
            <span>Perceptual motor training</span>
          </li>
          <li style="display: list-item;">
            <span>Intervention for psychiatric disorders</span>
          </li>
          <li style="display: list-item;">
            <span
              >Adaptive training for successful completion of daily living
              activities</span
            >
          </li>
        </ul>
      </div>
    </div>
    <div class="row" style="margin-top: 5%;">
      <div class="col-md-6">
        <h3 class="my-3">SPEECH THERAPY</h3>
        <p>
          Speech therapy may be an integral part of a patient’s rehabilitation.
          Courtesy Healthcare Services’ certified speech pathologists will work
          to increase a patient’s communication skills and the oral motor-skills
          required for safe swallowing. <br />
          <br />
          <b> Treatment will help to improve any of the following: </b>
        </p>
        <ul>
          <li style="display: list-item;"><span>Swallowing</span></li>
          <li style="display: list-item;">
            <span>Auditory comprehension</span>
          </li>
          <li style="display: list-item;"><span>Oral expression</span></li>
          <li style="display: list-item;">
            <span>Speech characteristics</span>
          </li>
          <li style="display: list-item;"><span>Written expression</span></li>
          <li style="display: list-item;">
            <span>Problem solving and memory</span>
          </li>
        </ul>
      </div>
      <div class="col-md-6">
        <img class="img-fluid" src="assets/img/courtesy/CHS_SP.jpg" alt="" />
      </div>
    </div>
  </div>
  <!-- /.container -->
</div>
