<div class="full-title">
  <div class="container">
    <!-- Page Heading/Breadcrumbs -->
    <h1 class="mt-4 mb-3">FAQ</h1>
    <div class="breadcrumb-main">
      <ol class="breadcrumb">
        <li class="breadcrumb-item">
          <a href="index.html">Home</a>
        </li>
        <li class="breadcrumb-item active">FAQ</li>
      </ol>
    </div>
  </div>
</div>

<div class="faq-main">
  <div class="container">
    <h2>Frequently Asked Questions</h2>
    <div class="accordion" id="accordionExample">
      <div class="card accordion-single" *ngFor="let item of faq">
        <div class="card-header" [id]="item.id + '_card'">
          <h5 class="mb-0">
            <button
              class="btn btn-link collapsed"
              type="button"
              data-toggle="collapse"
              [attr.data-target]="'#question_' + item.id"
              aria-expanded="false"
              aria-controls="collapseOne"
            >
              {{ item.question }}
            </button>
          </h5>
        </div>
        <div
          [id]="'question_' + item.id"
          class="collapse"
          data-parent="#accordionExample"
        >
          <div class="card-body">
            {{ item.answer }}
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
