import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-our-process',
  templateUrl: './our-process.component.html',
  styleUrls: ['./our-process.component.css']
})
export class OurProcessComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
