import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ApiService } from '../api.service';
import { Router } from '@angular/router';
import { first } from 'rxjs/operators';
import { LoginUser, Employee, Patient } from '../users';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { RestrictKeywordValidatorDirective } from '../restrict-keyword-validator';

@Component({
  selector: 'app-patientdetails',
  templateUrl: './patientdetails.component.html',
  styleUrls: ['./patientdetails.component.css']
})
export class PatientdetailsComponent implements OnInit {
  searchPatientForm: FormGroup;
  patientForm: FormGroup;
  message: string;
  status: string;
  patientDetailsFlag = false;
  updatePatient = false;
  ssnVal: string;

  servicesList = [];
  selectedServices = [];
  dropdownSettings: any = {};
  patientData: Patient;

  patientDetails = {
    address: '',
    city: '',
    age: '',
    contact: '',
    doctor: '',
    email: '',
    emergencyContactName: '',
    emergencyContactNumber: '',
    emergencyContactRelation: '',
    firstname: '',
    insuranceType: '',
    lastname: '',
    nurse: '',
    patientID: '',
    privateInsurance: '',
    services: '',
    ssn: '',
    state: '',
    zip: '',
    history: '',
    diagnoses: ''
  };
  userRole = null;

  constructor(
    private fb: FormBuilder,
    private dataService: ApiService,
    private router: Router
  ) {}

  ngOnInit() {
    this.userRole = this.dataService.getToken();
    this.searchPatientForm = this.fb.group({
      searchPatientID: [''],
      searchPatientSSN: ['']
    });

    this.servicesList = [
      { item_id: 1, item_text: 'Personal Care' },
      { item_id: 2, item_text: 'Companionship' },
      { item_id: 3, item_text: 'Transportation' }
    ];

    this.selectedServices = [];

    this.dropdownSettings = {
      singleSelection: false,
      idField: 'item_id',
      textField: 'item_text',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 3
    };

    this.patientForm = this.fb.group({
      patientID: [''],
      ssn: [''],
      firstname: [''],
      lastname: [''],
      age: [''],
      contact: [''],
      email: [''],
      emergencyContactName: [''],
      emergencyContactRelation: [''],
      emergencyContactNumber: [''],
      address: [''],
      city: [''],
      state: [''],
      zip: [''],
      diagnoses: [''],
      doctor: [''],
      nurseID: [''],
      services: [this.selectedServices],
      insuranceType: [''],
      privateInsurance: [''],
      history: ['']
    });
  }

  getPatientDetails(searchPatientForm) {
    this.dataService
      .getPatientDetails(
        searchPatientForm.value.searchPatientID,
        searchPatientForm.value.searchPatientSSN
      )
      .pipe(first())
      .subscribe(
        data => {
          if (this.userRole === 'ADMIN') {
            this.patientDetailsFlag = false;
            this.updatePatient = true;
            const servicesListArr = data.services.split(',');
            const selectedServiceList = [];
            for (let i = 0; i < this.servicesList.length; i++) {
              if (servicesListArr.includes(this.servicesList[i]['item_text'])) {
                selectedServiceList.push(this.servicesList[i]);
              }
            }
            this.patientForm.patchValue({
              patientID: data.patientID,
              ssn: data.ssn,
              firstname: data.firstname,
              lastname: data.lastname,
              age: data.age,
              contact: data.contact,
              email: data.email,
              emergencyContactName: data.emergencyContactName,
              emergencyContactRelation: data.emergencyContactRelation,
              emergencyContactNumber: data.emergencyContactNumber,
              address: data.address,
              city: data.city,
              state: data.state,
              zip: data.zip,
              diagnoses: data.diagnoses,
              doctor: data.doctor,
              nurseID: data.nurseID,
              services: selectedServiceList,
              insuranceType: data.insuranceType,
              privateInsurance: data.privateInsurance,
              history: data.history
            });
          } else {
            this.updatePatient = false;
            this.patientDetailsFlag = true;
            this.patientDetails = {
              address: data.address,
              city: data.city,
              age: data.age,
              contact: data.contact,
              doctor: data.doctor,
              email: data.email,
              emergencyContactName: data.emergencyContactName,
              emergencyContactNumber: data.emergencyContactNumber,
              emergencyContactRelation: data.emergencyContactRelation,
              firstname: data.firstname,
              insuranceType: data.insuranceType,
              lastname: data.lastname,
              nurse: data.nurse,
              patientID: data.patientID,
              privateInsurance: data.privateInsurance,
              services: data.services,
              ssn: data.ssn,
              state: data.state,
              zip: data.zip,
              history: data.history,
              diagnoses: data.diagnoses
            };
          }
          const redirect = this.dataService.redirectUrl
            ? this.dataService.redirectUrl
            : '/patient-details';
          this.router.navigate([redirect]);
        },
        error => {
          this.router.navigate(['/patient-details']);
          this.searchPatientForm.reset();
          this.status = '0';
          this.message = 'Patient not found.';
        }
      );
  }

  updatePatientDetails(patientForm) {
    const serviceArr = patientForm.value.services;
    let servicesOpted = '';
    for (let i = 0; i < serviceArr.length; i++) {
      servicesOpted = servicesOpted + serviceArr[i]['item_text'] + ',';
    }
    this.patientData = {
      patientID: patientForm.value.patientID,
      ssn: patientForm.value.ssn,
      firstname: patientForm.value.firstname,
      lastname: patientForm.value.lastname,
      age: patientForm.value.age,
      contact: patientForm.value.contact,
      email: patientForm.value.email,
      emergencyContactName: patientForm.value.emergencyContactName,
      emergencyContactRelation: patientForm.value.emergencyContactRelation,
      emergencyContactNumber: patientForm.value.emergencyContactNumber,
      address: patientForm.value.address,
      city: patientForm.value.city,
      state: patientForm.value.state,
      zip: patientForm.value.zip,
      diagnoses: patientForm.value.diagnoses,
      doctor: patientForm.value.doctor,
      nurseID: patientForm.value.nurseID,
      services: servicesOpted,
      insuranceType: patientForm.value.insuranceType,
      privateInsurance: patientForm.value.privateInsurance,
      history: patientForm.value.history
    };

    this.dataService
      .updatePatient(this.patientData)
      .pipe(first())
      .subscribe(
        data => {
          const redirect = this.dataService.redirectUrl
            ? this.dataService.redirectUrl
            : '/patient-details';
          this.router.navigate([redirect]);
          this.patientForm.reset();
          this.status = data.success;
          this.message = 'Patient ' + data.msg;
        },
        error => {
          this.router.navigate(['/admin']);
          this.patientForm.reset();
          this.message = 'Some Error Occurred.';
        }
      );
  }

  onBlurSSN(value: string) {
    this.ssnVal = this.searchPatientForm.get('searchPatientSSN').value;
    this.searchPatientForm
      .get('searchPatientSSN')
      .setValue(this.secureSSNFormat(value));
  }

  secureSSNFormat(inputVal) {
    var retVal = inputVal;
    if (inputVal == '') return (retVal = '');
    // if (topFrame.cnfParam("3413") == "MFF") {
    if (inputVal.length == 9) retVal = 'XXX-XX-' + inputVal.substr(5, 4);
    else alert('enter valid ssn');
    // }
    return retVal;
  }

  ValidSSNOnMasking(CorrectSSN, ValueIn) {
    var OriginalValue = CorrectSSN;
    return (
      ValueIn.substr(0, 3) +
      '-' +
      ValueIn.substr(3, 2) +
      '-' +
      ValueIn.substr(5)
    );
  }

  closeMessage() {
    this.message = null;
  }
}
