<div class="full-title">
  <div class="container">
    <!-- Page Heading/Breadcrumbs -->
    <h1 class="mt-4 mb-3">Admin</h1>
    <div class="breadcrumb-main">
      <ol class="breadcrumb">
        <li class="breadcrumb-item">
          <a href="index.html">Home</a>
        </li>
        <li class="breadcrumb-item active">Admin</li>
      </ol>
    </div>
  </div>
</div>
<!-- End banner Area -->

<div class="container">
  <div class="row d-flex justify-content-center" style="margin-bottom: 5%;">
    <div class="col-lg-10">
      <h3
        *ngIf="message"
        [style.background-color]="status == '1' ? '#42A02A' : '#E95A46'"
        style="color: white; text-align: center; font-size: 15px;"
      >
        {{ message }}
        <a
          style="float: right; font-size: 12px; color: black; text-align: center;
         margin-right: 2%; cursor: pointer;"
          (click)="closeMessage()"
        >
          X
        </a>
      </h3>
      <br />
      <div class="row">
        <div class="col-lg-3" (click)="createForm('employee')">
          <button type="button" class="btn btn-primary">
            Add New Employee
          </button>
        </div>
        <div class="col-lg-3" (click)="createForm('patient')">
          <button type="button" class="btn btn-primary">Add New Patient</button>
        </div>
        <div class="col-lg-3" (click)="createForm('testimonial')">
          <button type="button" class="btn btn-primary">Add Testimonial</button>
        </div>
        <div class="col-lg-3" (click)="createForm('faq')">
          <button type="button" class="btn btn-primary">Add FAQ</button>
        </div>
      </div>
      <br />
      <div class="row">
        <div class="col-lg-3" (click)="showApplicantList()">
          <button type="button" class="btn btn-primary">
            Show Applicant List
          </button>
        </div>
        <div class="col-lg-3" (click)="showContactedList()">
          <button type="button" class="btn btn-primary">
            Show Enquired List
          </button>
        </div>
        <div class="col-lg-3" (click)="showReferredPatient()">
          <button type="button" class="btn btn-primary">
            Show Referred Patient
          </button>
        </div>
        <div class="col-lg-3" (click)="showReferredNurse()">
          <button type="button" class="btn btn-primary">
            Show Referred Nurse
          </button>
        </div>
      </div>
    </div>
  </div>

  <div class="row d-flex justify-content-center" *ngIf="formType.employee">
    <div class="col-md-10">
      <h3>Add Employee</h3>
      <div class="jumbotron">
        <form
          [formGroup]="employeeForm"
          (ngSubmit)="insertEmployee(employeeForm)"
          autocomplete="off"
        >
          <div class="row">
            <div class="col-md-4 col-sm-12">
              <div class="form-group">
                <label for="employeeID">Employee ID</label>
                <input
                  type="text"
                  name="employeeID"
                  formControlName="employeeID"
                  autocomplete="off"
                  class="form-control input-sm"
                  placeholder="Employee ID"
                />
              </div>
            </div>
            <div class="col-md-4 col-sm-12">
              <div class="form-group">
                <label for="ssn">SSN</label>
                <input
                  type="text"
                  name="ssn"
                  formControlName="ssn"
                  autocomplete="off"
                  class="form-control input-sm"
                  placeholder="SSN"
                  (blur)="onBlurSSNEmployee($event.target.value)"
                />
              </div>
            </div>
            <div class="col-md-4 col-sm-12">
              <div class="form-group">
                <label for="role">Role</label>
                <select name="role" formControlName="role" class="form-control">
                  <option value="NURSE"> Nurse </option>
                  <option value="INHOUSE"> In-House </option>
                  <option value="OTHER"> Other </option>
                </select>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-6 col-sm-12">
              <div class="form-group">
                <label for="firstname">First Name</label>
                <input
                  type="text"
                  name="firstname"
                  formControlName="firstname"
                  autocomplete="off"
                  class="form-control input-sm"
                  placeholder="First Name"
                />
              </div>
            </div>
            <div class="col-md-6 col-sm-12">
              <div class="form-group">
                <label for="lastname">Last Name</label>
                <input
                  type="text"
                  name="lastname"
                  formControlName="lastname"
                  autocomplete="off"
                  class="form-control input-sm"
                  placeholder="Last Name"
                />
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-5 col-sm-12">
              <div class="form-group">
                <label for="contact">Phone Number</label>
                <input
                  type="text"
                  name="contact"
                  formControlName="contact"
                  autocomplete="off"
                  class="form-control input-sm"
                  placeholder="Number"
                />
              </div>
            </div>
            <div class="col-md-7 col-sm-12">
              <div class="form-group">
                <label for="email">Email</label>
                <input
                  type="text"
                  name="email"
                  formControlName="email"
                  autocomplete="off"
                  class="form-control input-sm"
                  placeholder="Email"
                />
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12 col-sm-12">
              <div class="form-group">
                <label for="address">Address</label>
                <input
                  type="text"
                  name="address"
                  formControlName="address"
                  autocomplete="off"
                  class="form-control input-sm"
                  placeholder="Address"
                />
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-4 col-sm-12">
              <div class="form-group">
                <label for="city">City</label>
                <input
                  type="text"
                  name="city"
                  formControlName="city"
                  autocomplete="off"
                  class="form-control input-sm"
                  placeholder="City"
                />
              </div>
            </div>
            <div class="col-md-4 col-sm-12">
              <div class="form-group">
                <label for="state">State/Province</label>
                <input
                  type="text"
                  name="state"
                  formControlName="state"
                  autocomplete="off"
                  class="form-control input-sm"
                  placeholder="State/Province"
                />
              </div>
            </div>
            <div class="col-md-4 col-sm-12">
              <div class="form-group">
                <label for="zip">Zip/Postal Code</label>
                <input
                  type="text"
                  name="zip"
                  formControlName="zip"
                  autocomplete="off"
                  class="form-control input-sm"
                  placeholder="Zip/Postal Code"
                />
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-6 col-sm-12">
              <label for="qualification">Qualification</label>
              <textarea
                name="qualification"
                formControlName="qualification"
                autocomplete="off"
                class="form-control"
                placeholder="Enter Qualification"
                rows="5"
              ></textarea>
            </div>
            <div class="col-md-6 col-sm-12">
              <label for="experience">Work Experience</label>
              <textarea
                name="experience"
                formControlName="experience"
                autocomplete="off"
                class="form-control"
                placeholder="Enter Experience"
                rows="5"
              ></textarea>
            </div>
          </div>
          <button type="submit" class="btn btn-success" style="margin-top: 4%;">
            Add Employee
          </button>
        </form>
      </div>
    </div>
  </div>

  <div class="row d-flex justify-content-center" *ngIf="formType.patient">
    <div class="col-md-10">
      <h3>Add Patient</h3>
      <div class="jumbotron">
        <form
          [formGroup]="patientForm"
          (ngSubmit)="insertPatient(patientForm)"
          autocomplete="off"
        >
          <div class="row">
            <div class="col-md-6 col-sm-12">
              <div class="form-group">
                <label for="patientID">Patient ID</label>
                <input
                  type="text"
                  name="patientID"
                  formControlName="patientID"
                  autocomplete="off"
                  class="form-control input-sm"
                  placeholder="Patient ID"
                />
              </div>
            </div>
            <div class="col-md-6 col-sm-12">
              <div class="form-group">
                <label for="ssn">SSN</label>
                <input
                  type="text"
                  name="ssn"
                  formControlName="ssn"
                  autocomplete="off"
                  class="form-control input-sm"
                  placeholder="SSN"
                  (blur)="onBlurSSNPatient($event.target.value)"
                />
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-6 col-sm-12">
              <div class="form-group">
                <label for="firstname">First Name</label>
                <input
                  type="text"
                  name="firstname"
                  formControlName="firstname"
                  autocomplete="off"
                  class="form-control input-sm"
                  placeholder="First Name"
                />
              </div>
            </div>
            <div class="col-md-6 col-sm-12">
              <div class="form-group">
                <label for="lastname">Last Name</label>
                <input
                  type="text"
                  name="lastname"
                  formControlName="lastname"
                  autocomplete="off"
                  class="form-control input-sm"
                  placeholder="Last Name"
                />
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-3 col-sm-12">
              <div class="form-group">
                <label for="age">Age</label>
                <input
                  type="text"
                  name="age"
                  formControlName="age"
                  autocomplete="off"
                  class="form-control input-sm"
                  placeholder="Age"
                />
              </div>
            </div>
            <div class="col-md-4 col-sm-12">
              <div class="form-group">
                <label for="contact">Phone Number</label>
                <input
                  type="text"
                  name="contact"
                  formControlName="contact"
                  autocomplete="off"
                  class="form-control input-sm"
                  placeholder="Number"
                />
              </div>
            </div>
            <div class="col-md-5 col-sm-12">
              <div class="form-group">
                <label for="email">Email</label>
                <input
                  type="text"
                  name="email"
                  formControlName="email"
                  autocomplete="off"
                  class="form-control input-sm"
                  placeholder="Email"
                />
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-4 col-sm-12">
              <div class="form-group">
                <label for="emergencyContactName">Emergency Contact Name</label>
                <input
                  type="text"
                  name="emergencyContactName"
                  formControlName="emergencyContactName"
                  autocomplete="off"
                  class="form-control input-sm"
                  placeholder="Emergency Contact Name"
                />
              </div>
            </div>
            <div class="col-md-4 col-sm-12">
              <div class="form-group">
                <label for="emergencyContactRelation"
                  >Emergency Contact Relation</label
                >
                <input
                  type="text"
                  name="emergencyContactRelation"
                  formControlName="emergencyContactRelation"
                  autocomplete="off"
                  class="form-control input-sm"
                  placeholder="Emergency Contact Relation"
                />
              </div>
            </div>
            <div class="col-md-4 col-sm-12">
              <div class="form-group">
                <label for="emergencyContactNumber"
                  >Emergency Contact Number</label
                >
                <input
                  type="text"
                  name="emergencyContactNumber"
                  formControlName="emergencyContactNumber"
                  autocomplete="off"
                  class="form-control input-sm"
                  placeholder="Emergency Contact Number"
                />
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12 col-sm-12">
              <div class="form-group">
                <label for="address">Address</label>
                <input
                  type="text"
                  name="address"
                  formControlName="address"
                  autocomplete="off"
                  class="form-control input-sm"
                  placeholder="Address"
                />
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-4 col-sm-12">
              <div class="form-group">
                <label for="city">City</label>
                <input
                  type="text"
                  name="city"
                  formControlName="city"
                  autocomplete="off"
                  class="form-control input-sm"
                  placeholder="City"
                />
              </div>
            </div>
            <div class="col-md-4 col-sm-12">
              <div class="form-group">
                <label for="state">State/Province</label>
                <input
                  type="text"
                  name="state"
                  formControlName="state"
                  autocomplete="off"
                  class="form-control input-sm"
                  placeholder="State/Province"
                />
              </div>
            </div>
            <div class="col-md-4 col-sm-12">
              <div class="form-group">
                <label for="zip">Zip/Postal Code</label>
                <input
                  type="text"
                  name="zip"
                  formControlName="zip"
                  autocomplete="off"
                  class="form-control input-sm"
                  placeholder="Zip/Postal Code"
                />
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12 col-sm-12">
              <label for="diagnoses">Diagnoses</label>
              <textarea
                name="diagnoses"
                formControlName="diagnoses"
                autocomplete="off"
                class="form-control"
                placeholder="Enter Diagnoses"
                rows="5"
              ></textarea>
            </div>
          </div>
          <div class="row">
            <div class="col-md-6 col-sm-12">
              <div class="form-group">
                <label for="doctor">Consulting Doctor</label>
                <input
                  type="text"
                  name="doctor"
                  formControlName="doctor"
                  autocomplete="off"
                  class="form-control input-sm"
                  placeholder="Consulting Doctor"
                />
              </div>
            </div>
            <div class="col-md-6 col-sm-12">
              <div class="form-group">
                <label for="nurseID">Associated Nurse ID</label>
                <input
                  type="text"
                  name="nurseID"
                  formControlName="nurseID"
                  autocomplete="off"
                  class="form-control input-sm"
                  placeholder="Associated Nurse ID"
                />
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12 col-sm-12">
              <label for="services">Services</label>
              <ng-multiselect-dropdown
                name="services"
                [placeholder]="'Select Services'"
                [data]="servicesList"
                formControlName="services"
                [settings]="dropdownSettings"
              >
              </ng-multiselect-dropdown>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12 col-sm-12">
              <label style="margin-top: 2%;">Insurance Type</label>
            </div>
            <div class="col-md-3 col-sm-12">
              <div class="form-group">
                <input
                  id="medicaid"
                  type="radio"
                  formControlName="insuranceType"
                  value="Medicaid"
                  name="insuranceType"
                />
                <label for="medicaid">Medicaid</label>
              </div>
            </div>
            <div class="col-md-3 col-sm-12">
              <div class="form-group">
                <input
                  id="lti"
                  type="radio"
                  formControlName="insuranceType"
                  value="Long Term Insurance"
                  name="insuranceType"
                />
                <label for="lti">Long Term Insurance</label>
              </div>
            </div>
            <div class="col-md-3 col-sm-12">
              <div class="form-group">
                <input
                  id="private"
                  type="radio"
                  formControlName="insuranceType"
                  value="Private"
                  name="insuranceType"
                />
                <label for="private">Private Insurance</label>
              </div>
            </div>
            <div class="col-md-3 col-sm-12">
              <div class="form-group">
                <input
                  id="self"
                  type="radio"
                  formControlName="insuranceType"
                  value="Self"
                  name="insuranceType"
                />
                <label for="self">Self-Pay</label>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12 col-sm-12">
              <div class="form-group">
                <label for="privateInsurance">Private Insurance</label>
                <input
                  type="text"
                  name="privateInsurance"
                  formControlName="privateInsurance"
                  autocomplete="off"
                  class="form-control input-sm"
                  placeholder="Private Insurance"
                />
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12 col-sm-12">
              <label for="history">History</label>
              <textarea
                name="history"
                formControlName="history"
                autocomplete="off"
                class="form-control"
                placeholder="Enter History"
                rows="5"
              ></textarea>
            </div>
          </div>
          <button type="submit" class="btn btn-success" style="margin-top: 4%;">
            Add Patient
          </button>
        </form>
      </div>
    </div>
  </div>

  <div class="row d-flex justify-content-center" *ngIf="formType.testimonial">
    <div class="col-md-6">
      <h3>Add Testimonial</h3>
      <div class="jumbotron">
        <form
          [formGroup]="testimonialForm"
          (ngSubmit)="insertTestimonial(testimonialForm)"
          autocomplete="off"
        >
          <div class="form-group">
            <label for="clientname">Client Name</label>
            <input
              type="text"
              name="clientname"
              formControlName="clientname"
              autocomplete="off"
              class="form-control input-sm"
              placeholder="Client Name"
            />
          </div>
          <div class="form-group">
            <label for="feedback">Feedback</label>
            <textarea
              name="feedback"
              formControlName="feedback"
              autocomplete="off"
              class="form-control"
              placeholder="Enter Feedback"
              rows="5"
            ></textarea>
          </div>
          <div class="form-group">
            <label for="feedbackDate">Feedback Date</label>
            <input
              type="date"
              name="feedbackDate"
              formControlName="feedbackDate"
              autocomplete="off"
              class="form-control input-sm"
            />
          </div>
          <button type="submit" class="btn btn-success" style="margin-top: 4%;">
            Add Testimonial
          </button>
        </form>
      </div>
    </div>
  </div>
  <div class="row d-flex justify-content-center" *ngIf="formType.faq">
    <div class="col-md-6">
      <h3>Add FAQ</h3>
      <div class="jumbotron">
        <form
          [formGroup]="faqForm"
          (ngSubmit)="insertFAQ(faqForm)"
          autocomplete="off"
        >
          <div class="form-group">
            <label for="question">Question</label>
            <input
              type="text"
              name="question"
              formControlName="question"
              autocomplete="off"
              class="form-control input-sm"
              placeholder="Question"
            />
          </div>
          <div class="form-group">
            <label for="answer">Answer</label>
            <textarea
              name="answer"
              formControlName="answer"
              autocomplete="off"
              class="form-control"
              placeholder="Enter Answer"
              rows="5"
            ></textarea>
          </div>
          <button type="submit" class="btn btn-success" style="margin-top: 4%;">
            Add FAQ
          </button>
        </form>
      </div>
    </div>
  </div>
  <div class="rowd d-flex justify-content-center" *ngIf="formType.applicant">
    <div class="col-md-10">
      <h3>List Of Applicants</h3>
      <div class="jumbotron">
        <div class="table-responsive">
          <table class="table table-bordered">
            <thead>
              <tr>
                <th>Name</th>
                <th>Contact</th>
                <th>Email</th>
                <th>Address</th>
                <th>Qualification</th>
                <th>Experience</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let item of applicantList">
                <td>{{ item.firstname }} {{ item.lastname }}</td>
                <td>{{ item.contact }}</td>
                <td>{{ item.email }}</td>
                <td>{{ item.city }}, {{ item.state }}, {{ item.zip }}</td>
                <td>{{ item.qualification }}</td>
                <td>{{ item.experience }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
  <div class="rowd d-flex justify-content-center" *ngIf="formType.contacted">
    <div class="col-md-10">
      <h3>List Of Enquiries</h3>
      <div class="jumbotron">
        <div class="table-responsive">
          <table class="table table-bordered">
            <thead>
              <tr>
                <th>Name</th>
                <th>Contact</th>
                <th>Email</th>
                <th>Address</th>
                <th>Enquiry</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let item of contactedList">
                <td>{{ item.firstname }} {{ item.lastname }}</td>
                <td>{{ item.contact }}</td>
                <td>{{ item.email }}</td>
                <td>{{ item.city }}, {{ item.state }}, {{ item.zip }}</td>
                <td>{{ item.comment }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
  <div
    class="rowd d-flex justify-content-center"
    *ngIf="formType.referredPatient"
  >
    <div class="col-md-10">
      <h3>List Of Referred Patients</h3>
      <div class="jumbotron">
        <div class="table-responsive">
          <table class="table table-bordered">
            <thead>
              <tr>
                <th>Name</th>
                <th>Age</th>
                <th>Contact</th>
                <th>Email</th>
                <th>Address</th>
                <th>Diagnoses</th>
                <th>Services</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let item of refPatient">
                <td>{{ item.firstname }} {{ item.lastname }}</td>
                <td>{{ item.age }}</td>
                <td>{{ item.contact }}</td>
                <td>{{ item.email }}</td>
                <td>{{ item.city }}, {{ item.state }}, {{ item.zip }}</td>
                <td>{{ item.diagnoses }}</td>
                <td>{{ item.services }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
  <div
    class="rowd d-flex justify-content-center"
    *ngIf="formType.referredNurse"
  >
    <div class="col-md-10">
      <h3>List Of Referred Nurses</h3>
      <div class="jumbotron">
        <div class="table-responsive">
          <table class="table table-bordered">
            <thead>
              <tr>
                <th>Name</th>
                <th>Contact</th>
                <th>Email</th>
                <th>Address</th>
                <th>Qualification</th>
                <th>Experience</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let item of refNurse">
                <td>{{ item.firstname }} {{ item.lastname }}</td>
                <td>{{ item.contact }}</td>
                <td>{{ item.email }}</td>
                <td>{{ item.city }}, {{ item.state }}, {{ item.zip }}</td>
                <td>{{ item.qualification }}</td>
                <td>{{ item.experience }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</div>
