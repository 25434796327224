import { Injectable, Output, EventEmitter } from '@angular/core';
import { map } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import {
  LoginUser,
  Employee,
  Patient,
  Career,
  Contact,
  ReferPatient,
  ReferNurse
} from './users';
import { environment } from '../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ApiService {
  redirectUrl: string;
  baseUrl: string = 'https://www.courtesyhs.com//courtesy-api/';
  @Output() getLoggedInName: EventEmitter<any> = new EventEmitter();

  constructor(private httpClient: HttpClient) {}

  public userlogin(username, password) {
    return this.httpClient
      .post<any>(this.baseUrl + '/login.php', { username, password })
      .pipe(
        map(LoginUser => {
          this.setToken(LoginUser[0].role);
          this.getLoggedInName.emit(true);
          return LoginUser[0];
        })
      );
  }

  public addEmployee(employeeData: Employee) {
    return this.httpClient
      .post<any>(this.baseUrl + '/addEmployee.php', { employeeData })
      .pipe(
        map(Employee => {
          return Employee.response;
        })
      );
  }

  public getEmployeeDetails(employeeID, employeeSSN) {
    return this.httpClient
      .post<any>(this.baseUrl + '/getEmployeeDetails.php', {
        employeeID,
        employeeSSN
      })
      .pipe(
        map(EmployeeDetails => {
          return EmployeeDetails[0];
        })
      );
  }

  public updateEmployee(employeeData: Employee) {
    return this.httpClient
      .post<any>(this.baseUrl + '/updateEmployeeDetails.php', { employeeData })
      .pipe(
        map(EmployeeUpdate => {
          return EmployeeUpdate.response;
        })
      );
  }

  public getEmployeeSchedule(employeeID) {
    return this.httpClient
      .post<any>(this.baseUrl + '/getEmployeeSchedule.php', { employeeID })
      .pipe(
        map(EmployeeSchedule => {
          return EmployeeSchedule;
        })
      );
  }

  public updateSchedule(employeeID, timing, day, scheduleVal) {
    return this.httpClient
      .post<any>(this.baseUrl + '/updateEmployeeSchedule.php', {
        employeeID,
        timing,
        day,
        scheduleVal
      })
      .pipe(
        map(ScheduleUpdate => {
          return ScheduleUpdate.response;
        })
      );
  }

  public addPatient(patientData: Patient) {
    return this.httpClient
      .post<any>(this.baseUrl + '/addPatient.php', { patientData })
      .pipe(
        map(Patient => {
          return Patient.response;
        })
      );
  }

  public getPatientDetails(patientID, patientSSN) {
    return this.httpClient
      .post<any>(this.baseUrl + '/getPatientDetails.php', {
        patientID,
        patientSSN
      })
      .pipe(
        map(PatientDetails => {
          return PatientDetails[0];
        })
      );
  }

  public updatePatient(patientData: Patient) {
    return this.httpClient
      .post<any>(this.baseUrl + '/updatePatientDetails.php', { patientData })
      .pipe(
        map(PatientUpdate => {
          return PatientUpdate.response;
        })
      );
  }

  public addTestimonial(clientname, feedback, feedbackDate) {
    return this.httpClient
      .post<any>(this.baseUrl + '/addTestimonial.php', {
        clientname,
        feedback,
        feedbackDate
      })
      .pipe(
        map(Testimonial => {
          console.log(Testimonial.response.msg);
          return Testimonial.response;
        })
      );
  }

  public getTestimonials() {
    return this.httpClient
      .post<any>(this.baseUrl + '/getTestimonial.php', {})
      .pipe(
        map(TestimonialData => {
          console.log(TestimonialData);
          return TestimonialData;
        })
      );
  }

  public addFAQ(question, answer) {
    return this.httpClient
      .post<any>(this.baseUrl + '/addFAQ.php', {
        question,
        answer
      })
      .pipe(
        map(FAQ => {
          console.log(FAQ.response.msg);
          return FAQ.response;
        })
      );
  }

  public getFAQ() {
    return this.httpClient.post<any>(this.baseUrl + '/getFAQ.php', {}).pipe(
      map(FAQData => {
        console.log(FAQData);
        return FAQData;
      })
    );
  }

  public changePassword(employeeID, password) {
    return this.httpClient
      .post<any>(this.baseUrl + '/changePassword.php', { employeeID, password })
      .pipe(
        map(PasswordUpdate => {
          return PasswordUpdate.response;
        })
      );
  }

  public addApplicant(careerData: Career) {
    return this.httpClient
      .post<any>(this.baseUrl + '/addApplicant.php', { careerData })
      .pipe(
        map(Career => {
          console.log(Career.response.msg);
          return Career.response;
        })
      );
  }

  public getApplicant() {
    return this.httpClient
      .post<any>(this.baseUrl + '/getApplicant.php', {})
      .pipe(
        map(ApplicantData => {
          console.log(ApplicantData);
          return ApplicantData;
        })
      );
  }

  public addEnquiry(contactData: Contact) {
    return this.httpClient
      .post<any>(this.baseUrl + '/addEnquiry.php', { contactData })
      .pipe(
        map(Enquiry => {
          console.log(Enquiry.response.msg);
          return Enquiry.response;
        })
      );
  }

  public getEnquiry() {
    return this.httpClient.post<any>(this.baseUrl + '/getEnquiry.php', {}).pipe(
      map(EnquiryData => {
        console.log(EnquiryData);
        return EnquiryData;
      })
    );
  }

  public addPatientReferral(patientRefer: ReferPatient) {
    return this.httpClient
      .post<any>(this.baseUrl + '/addReferredPatient.php', { patientRefer })
      .pipe(
        map(RefPatient => {
          console.log(RefPatient.response.msg);
          return RefPatient.response;
        })
      );
  }

  public getReferredPatient() {
    return this.httpClient
      .post<any>(this.baseUrl + '/getRefPatient.php', {})
      .pipe(
        map(RefPatient => {
          console.log(RefPatient);
          return RefPatient;
        })
      );
  }

  public addNurseReferral(nurseRefer: ReferNurse) {
    return this.httpClient
      .post<any>(this.baseUrl + '/addReferredNurse.php', { nurseRefer })
      .pipe(
        map(RefNurse => {
          console.log(RefNurse.response.msg);
          return RefNurse.response;
        })
      );
  }

  public getReferredNurse() {
    return this.httpClient
      .post<any>(this.baseUrl + '/getRefNurse.php', {})
      .pipe(
        map(RefNurse => {
          console.log(RefNurse);
          return RefNurse;
        })
      );
  }

  setToken(token: string) {
    //localStorage.setItem('token', token);
    sessionStorage.setItem('token', token);
  }

  getToken() {
    //return localStorage.getItem('token');
    return sessionStorage.getItem('token');
  }

  deleteToken() {
    //localStorage.removeItem('token');
    sessionStorage.removeItem('token');
  }

  isLoggedIn() {
    const usertoken = this.getToken();
    if (usertoken != null) {
      return true;
    }
    return false;
  }
}
