<div class="full-title">
  <div class="container">
    <!-- Page Heading/Breadcrumbs -->
    <h1 class="mt-4 mb-3">About Us</h1>
    <div class="breadcrumb-main">
      <ol class="breadcrumb">
        <li class="breadcrumb-item">
          <a href="index.html">Home</a>
        </li>
        <li class="breadcrumb-item active">About Us</li>
      </ol>
    </div>
  </div>
</div>
<!-- End banner Area -->

<!-- Start About Us Area -->
<div class="container">
  <!-- About Section -->
  <div class="about-main">
    <section>
      <div class="row">
        <div class="col-lg-12" style="text-align: center;">
          <h3>Welcome to Courtesy</h3>
          <h5>We are committed to care!</h5>
          <br />
          <p>
            Courtesy Healthcare Services is a class A Professional Home Care
            Agency locally operated and serving the Northern Virginia
            communities. Our business and professional calling is in providing
            care for our clients as they work with care management in attaining
            their goal for maximum potential in health and well-being. It is our
            aim to provide exceptional customer care with courtesy.
          </p>
        </div>
      </div>
    </section>
    <br />
    <div
      class="parallax-banner parallax100"
      style="background-image: url(assets/img/courtesy/banner_1.jpg);
    text-align: center; padding-top: 135px; padding-bottom: 158px;"
    ></div>
    <section style="margin-top: 5%;">
      <div class="row">
        <div class="col-lg-12" style="text-align: center;">
          <p>
            At Courtesy Healthcare Services; we understand how important it is
            to have someone you can depend on when you or someone you love wants
            to stay at home to maintain the highest level of health and
            independence. Our mission is to improve people’s lives and that is
            what we do every day. With a broad range of home care expertise, our
            trained professionals offer comprehensive home care and supportive
            services that are cost- effective and highly personalized.
          </p>
          <br />
          <p>
            Whether you need help for a short time after you come home from a
            hospital stay or you need assistance in doing some of the tasks of
            daily living, Courtesy Healthcare Services is here for you and your
            family. We’re ready to listen to what you need and to talk with you
            about the many services and care we can provide. If you are looking
            for people you can trust, please call us first. When it matters
            most, count on us.
          </p>
        </div>
      </div>
    </section>
    <!-- /.row -->
  </div>
</div>

<!-- End About Us Area -->
