import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ApiService } from '../api.service';
import { Router } from '@angular/router';
import { first } from 'rxjs/operators';
import { LoginUser, Employee, Patient } from '../users';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { RestrictKeywordValidatorDirective } from '../restrict-keyword-validator';
@Component({
  selector: 'app-admin',
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.css']
})
export class AdminComponent implements OnInit {
  formType = {
    employee: false,
    patient: false,
    testimonial: false,
    faq: false,
    applicant: false,
    contacted: false,
    referredPatient: false,
    referredNurse: false
  };
  applicantList = [];
  contactedList = [];
  refPatient: [];
  refNurse = [];

  message: string;
  status: string;
  ssnPatient: string;
  ssnEmployee: string;

  employeeData: Employee;
  patientData: Patient;

  servicesList = [];
  selectedServices = [];
  dropdownSettings: any = {};

  employeeForm: FormGroup;
  patientForm: FormGroup;
  testimonialForm: FormGroup;
  faqForm: FormGroup;

  constructor(
    private fb: FormBuilder,
    private dataService: ApiService,
    private router: Router
  ) {}

  ngOnInit() {
    this.servicesList = [
      { item_id: 1, item_text: 'Personal Care' },
      { item_id: 2, item_text: 'Companionship' },
      { item_id: 3, item_text: 'Transportation' }
    ];

    this.selectedServices = [];

    this.dropdownSettings = {
      singleSelection: false,
      idField: 'item_id',
      textField: 'item_text',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 3
    };

    this.employeeForm = this.fb.group({
      employeeID: [''],
      ssn: [''],
      role: [''],
      firstname: [''],
      lastname: [''],
      contact: [''],
      email: [''],
      address: [''],
      city: [''],
      state: [''],
      zip: [''],
      qualification: [''],
      experience: ['']
    });

    this.patientForm = this.fb.group({
      patientID: [''],
      ssn: [''],
      firstname: [''],
      lastname: [''],
      age: [''],
      contact: [''],
      email: [''],
      emergencyContactName: [''],
      emergencyContactRelation: [''],
      emergencyContactNumber: [''],
      address: [''],
      city: [''],
      state: [''],
      zip: [''],
      diagnoses: [''],
      doctor: [''],
      nurseID: [''],
      services: [this.selectedServices],
      insuranceType: [''],
      privateInsurance: [''],
      history: ['']
    });

    this.testimonialForm = this.fb.group({
      clientname: [''],
      feedback: [''],
      feedbackDate: ['']
    });

    this.faqForm = this.fb.group({
      question: [''],
      answer: ['']
    });
  }

  insertFAQ(faqForm) {
    this.dataService
      .addFAQ(faqForm.value.question, faqForm.value.answer)
      .pipe(first())
      .subscribe(
        data => {
          const redirect = this.dataService.redirectUrl
            ? this.dataService.redirectUrl
            : '/admin';
          this.router.navigate([redirect]);
          this.faqForm.reset();
          this.status = data.success;
          this.message = 'FAQ ' + data.msg;
        },
        error => {
          this.router.navigate(['/admin']);
          this.faqForm.reset();
          this.message = 'Some Error Occurred.';
        }
      );
  }

  insertTestimonial(testimonialForm) {
    this.dataService
      .addTestimonial(
        testimonialForm.value.clientname,
        testimonialForm.value.feedback,
        testimonialForm.value.feedbackDate
      )
      .pipe(first())
      .subscribe(
        data => {
          const redirect = this.dataService.redirectUrl
            ? this.dataService.redirectUrl
            : '/admin';
          this.router.navigate([redirect]);
          this.testimonialForm.reset();
          this.status = data.success;
          this.message = 'Testimonial ' + data.msg;
        },
        error => {
          this.router.navigate(['/admin']);
          this.testimonialForm.reset();
          this.message = 'Some Error Occurred.';
        }
      );
  }

  insertEmployee(employeeForm) {
    this.employeeData = {
      employeeID: employeeForm.value.employeeID,
      ssn: employeeForm.value.ssn,
      role: employeeForm.value.role,
      firstname: employeeForm.value.firstname,
      lastname: employeeForm.value.lastname,
      contact: employeeForm.value.contact,
      email: employeeForm.value.email,
      address: employeeForm.value.address,
      city: employeeForm.value.city,
      state: employeeForm.value.state,
      zip: employeeForm.value.zip,
      qualification: employeeForm.value.qualification,
      experience: employeeForm.value.experience
    };

    this.dataService
      .addEmployee(this.employeeData)
      .pipe(first())
      .subscribe(
        data => {
          const redirect = this.dataService.redirectUrl
            ? this.dataService.redirectUrl
            : '/admin';
          this.router.navigate([redirect]);
          this.employeeForm.reset();
          this.status = data.success;
          this.message = 'Employee ' + data.msg;
        },
        error => {
          this.router.navigate(['/admin']);
          this.employeeForm.reset();
          this.message = 'Some Error Occurred.';
        }
      );
  }

  insertPatient(patientForm) {
    const serviceArr = patientForm.value.services;
    let servicesOpted = '';
    for (let i = 0; i < serviceArr.length; i++) {
      servicesOpted = servicesOpted + serviceArr[i]['item_text'] + ',';
    }
    this.patientData = {
      patientID: patientForm.value.patientID,
      ssn: patientForm.value.ssn,
      firstname: patientForm.value.firstname,
      lastname: patientForm.value.lastname,
      age: patientForm.value.age,
      contact: patientForm.value.contact,
      email: patientForm.value.email,
      emergencyContactName: patientForm.value.emergencyContactName,
      emergencyContactRelation: patientForm.value.emergencyContactRelation,
      emergencyContactNumber: patientForm.value.emergencyContactNumber,
      address: patientForm.value.address,
      city: patientForm.value.city,
      state: patientForm.value.state,
      zip: patientForm.value.zip,
      diagnoses: patientForm.value.diagnoses,
      doctor: patientForm.value.doctor,
      nurseID: patientForm.value.nurseID,
      services: servicesOpted,
      insuranceType: patientForm.value.insuranceType,
      privateInsurance: patientForm.value.privateInsurance,
      history: patientForm.value.history
    };

    this.dataService
      .addPatient(this.patientData)
      .pipe(first())
      .subscribe(
        data => {
          const redirect = this.dataService.redirectUrl
            ? this.dataService.redirectUrl
            : '/admin';
          this.router.navigate([redirect]);
          this.patientForm.reset();
          this.status = data.success;
          this.message = 'Patient ' + data.msg;
        },
        error => {
          this.router.navigate(['/admin']);
          this.patientForm.reset();
          this.message = 'Some Error Occurred.';
        }
      );
  }

  closeMessage() {
    this.message = null;
  }

  createForm(formName) {
    this.resetAllForms();
    this.formType[formName] = true;
  }

  showApplicantList() {
    this.resetAllForms();
    this.formType['applicant'] = true;
    this.dataService
      .getApplicant()
      .pipe(first())
      .subscribe(data => {
        this.applicantList = data;
      });
  }

  showContactedList() {
    this.resetAllForms();
    this.formType['contacted'] = true;
    this.dataService
      .getEnquiry()
      .pipe(first())
      .subscribe(data => {
        this.contactedList = data;
      });
  }

  showReferredPatient() {
    this.resetAllForms();
    this.formType['referredPatient'] = true;
    this.dataService
      .getReferredPatient()
      .pipe(first())
      .subscribe(data => {
        this.refPatient = data;
      });
  }

  showReferredNurse() {
    this.resetAllForms();
    this.formType['referredNurse'] = true;
    this.dataService
      .getReferredNurse()
      .pipe(first())
      .subscribe(data => {
        this.refNurse = data;
      });
  }

  resetAllForms() {
    this.formType['employee'] = false;
    this.formType['patient'] = false;
    this.formType['testimonial'] = false;
    this.formType['faq'] = false;
    this.formType['applicant'] = false;
    this.formType['contacted'] = false;
    this.formType['referredPatient'] = false;
    this.formType['referredNurse'] = false;
  }

  onBlurSSNPatient(value: string) {
    this.ssnPatient = this.patientForm.get('ssn').value;
    this.patientForm.get('ssn').setValue(this.secureSSNFormat(value));
  }

  onBlurSSNEmployee(value: string) {
    this.ssnEmployee = this.employeeForm.get('ssn').value;
    this.employeeForm.get('ssn').setValue(this.secureSSNFormat(value));
  }

  secureSSNFormat(inputVal) {
    var retVal = inputVal;
    if (inputVal == '') return (retVal = '');
    // if (topFrame.cnfParam("3413") == "MFF") {
    if (inputVal.length == 9) retVal = 'XXX-XX-' + inputVal.substr(5, 4);
    else alert('enter valid ssn');
    // }
    return retVal;
  }

  ValidSSNOnMasking(CorrectSSN, ValueIn) {
    var OriginalValue = CorrectSSN;
    return (
      ValueIn.substr(0, 3) +
      '-' +
      ValueIn.substr(3, 2) +
      '-' +
      ValueIn.substr(5)
    );
  }
}
