<div class="full-title">
  <div class="container">
    <!-- Page Heading/Breadcrumbs -->
    <h1 class="mt-4 mb-3">Employee Details</h1>
    <div class="breadcrumb-main">
      <ol class="breadcrumb">
        <li class="breadcrumb-item">
          <a href="index.html">Home</a>
        </li>
        <li class="breadcrumb-item active">Employee Details</li>
      </ol>
    </div>
  </div>
</div>

<div class="container" style="padding-bottom: 3%;">
  <div class="row d-flex justify-content-center" style="margin-bottom: 5%;">
    <div class="col-lg-10">
      <h3
        *ngIf="message"
        [style.background-color]="status == '1' ? '#42A02A' : '#E95A46'"
        style="color: white; text-align: center; font-size: 15px;"
      >
        {{ message }}
        <a
          style="float: right; font-size: 12px; color: black; text-align: center; margin-right: 2%; cursor: pointer;"
          (click)="closeMessage()"
        >
          X
        </a>
      </h3>
      <br />
      <form
        [formGroup]="searchEmployeeForm"
        (ngSubmit)="getEmployeeDetails(searchEmployeeForm)"
        autocomplete="off"
      >
        <div class="row d-flex justify-content-center">
          <div class="col-md-4 col-sm-12">
            <div class="form-group">
              <label for="searchEmployeeID">Enter Employee ID</label>
              <input
                type="text"
                name="searchEmployeeID"
                formControlName="searchEmployeeID"
                autocomplete="off"
                class="form-control input-sm"
                placeholder="Enter Employee ID"
              />
            </div>
          </div>
          <div class="col-md-4 col-sm-12">
            <div class="form-group">
              <label for="searchEmployeeSSN">Enter Employee SSN</label>
              <input
                type="text"
                name="searchEmployeeSSN"
                formControlName="searchEmployeeSSN"
                autocomplete="off"
                class="form-control input-sm"
                placeholder="Enter Employee SSN"
                (blur)="onBlurSSN($event.target.value)"
              />
            </div>
          </div>
          <div class="col-md-4 col-sm-12">
            <button
              type="submit"
              class="btn btn-success"
              style="margin-top: 10.5%;"
            >
              Search Employee
            </button>
          </div>
        </div>
      </form>
    </div>
  </div>
  <div *ngIf="employeeDetailsFlag">
    <div style=" border: 1px solid #ddd; margin-top: 1em; ">
      <h1 style="font-size: 12px; text-align: center;">
        <span
          style="display: inline; border: 1px solid #ddd; background: #fff; padding: 5px 10px; position: relative; top: -1em;"
        >
          Personal Details
        </span>
      </h1>
      <div class="row" style="margin: 2%;">
        <div class="col-md-3 col-sm-12">
          <strong>Name:</strong> {{ employeeDetails.firstname }}
          {{ employeeDetails.lastname }}
        </div>
        <div class="col-md-2 col-sm-12">
          <strong>Role:</strong> {{ employeeDetails.role }}
        </div>
        <div class="col-md-2 col-sm-12">
          <strong>SSN:</strong> {{ employeeDetails.ssn }}
        </div>
        <div class="col-md-2 col-sm-12">
          <strong>Contact:</strong> {{ employeeDetails.contact }}
        </div>
        <div class="col-md-3 col-sm-12">
          <strong>Email ID:</strong> {{ employeeDetails.email }}
        </div>
      </div>
      <div class="row" style="margin: 2%;">
        <div class="col-md-12 col-sm-12">
          <strong>Address:</strong> {{ employeeDetails.address }},
          {{ employeeDetails.city }}, {{ employeeDetails.state }},
          {{ employeeDetails.zip }}
        </div>
      </div>
      <div class="row" style="margin: 2%;">
        <div class="col-md-12 col-sm-12">
          <strong>Qualification/Certification:</strong>
          {{ employeeDetails.qualification }}
        </div>
      </div>
      <div class="row" style="margin: 2%;">
        <div class="col-md-12 col-sm-12">
          <strong>Work Experience:</strong> {{ employeeDetails.experience }}
        </div>
      </div>
    </div>
    <div class="row" style="margin: 2%;">
      To see additional information or update &nbsp; &nbsp;
      <a [routerLink]="['/login']">Login</a>
    </div>
  </div>

  <div class="row d-flex justify-content-center" *ngIf="updateEmployee">
    <div class="col-md-10">
      <h3>Update Employee</h3>
      <div class="jumbotron">
        <form
          [formGroup]="employeeForm"
          (ngSubmit)="updateEmployeeDetails(employeeForm)"
          autocomplete="off"
        >
          <div class="row">
            <div class="col-md-4 col-sm-12">
              <div class="form-group">
                <label for="employeeID">Employee ID</label>
                <input
                  type="text"
                  name="employeeID"
                  formControlName="employeeID"
                  autocomplete="off"
                  class="form-control input-sm"
                  placeholder="Employee ID"
                  disabled
                />
              </div>
            </div>
            <div class="col-md-4 col-sm-12">
              <div class="form-group">
                <label for="ssn">SSN</label>
                <input
                  type="text"
                  name="ssn"
                  formControlName="ssn"
                  autocomplete="off"
                  class="form-control input-sm"
                  placeholder="SSN"
                  disabled
                />
              </div>
            </div>
            <div class="col-md-4 col-sm-12">
              <div class="form-group">
                <label for="role">Role</label>
                <select name="role" formControlName="role" class="form-control">
                  <option value="NURSE"> Nurse </option>
                  <option value="INHOUSE"> In-House </option>
                  <option value="OTHER"> Other </option>
                </select>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-6 col-sm-12">
              <div class="form-group">
                <label for="firstname">First Name</label>
                <input
                  type="text"
                  name="firstname"
                  formControlName="firstname"
                  autocomplete="off"
                  class="form-control input-sm"
                  placeholder="First Name"
                />
              </div>
            </div>
            <div class="col-md-6 col-sm-12">
              <div class="form-group">
                <label for="lastname">Last Name</label>
                <input
                  type="text"
                  name="lastname"
                  formControlName="lastname"
                  autocomplete="off"
                  class="form-control input-sm"
                  placeholder="Last Name"
                />
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-5 col-sm-12">
              <div class="form-group">
                <label for="contact">Phone Number</label>
                <input
                  type="text"
                  name="contact"
                  formControlName="contact"
                  autocomplete="off"
                  class="form-control input-sm"
                  placeholder="Number"
                />
              </div>
            </div>
            <div class="col-md-7 col-sm-12">
              <div class="form-group">
                <label for="email">Email</label>
                <input
                  type="text"
                  name="email"
                  formControlName="email"
                  autocomplete="off"
                  class="form-control input-sm"
                  placeholder="Email"
                />
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12 col-sm-12">
              <div class="form-group">
                <label for="address">Address</label>
                <input
                  type="text"
                  name="address"
                  formControlName="address"
                  autocomplete="off"
                  class="form-control input-sm"
                  placeholder="Address"
                />
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-4 col-sm-12">
              <div class="form-group">
                <label for="city">City</label>
                <input
                  type="text"
                  name="city"
                  formControlName="city"
                  autocomplete="off"
                  class="form-control input-sm"
                  placeholder="City"
                />
              </div>
            </div>
            <div class="col-md-4 col-sm-12">
              <div class="form-group">
                <label for="state">State/Province</label>
                <input
                  type="text"
                  name="state"
                  formControlName="state"
                  autocomplete="off"
                  class="form-control input-sm"
                  placeholder="State/Province"
                />
              </div>
            </div>
            <div class="col-md-4 col-sm-12">
              <div class="form-group">
                <label for="zip">Zip/Postal Code</label>
                <input
                  type="text"
                  name="zip"
                  formControlName="zip"
                  autocomplete="off"
                  class="form-control input-sm"
                  placeholder="Zip/Postal Code"
                />
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-6 col-sm-12">
              <label for="qualification">Qualification</label>
              <textarea
                name="qualification"
                formControlName="qualification"
                autocomplete="off"
                class="form-control"
                placeholder="Enter Qualification"
                rows="5"
              ></textarea>
            </div>
            <div class="col-md-6 col-sm-12">
              <label for="experience">Work Experience</label>
              <textarea
                name="experience"
                formControlName="experience"
                autocomplete="off"
                class="form-control"
                placeholder="Enter Experience"
                rows="5"
              ></textarea>
            </div>
          </div>
          <button type="submit" class="btn btn-success" style="margin-top: 4%;">
            Update Employee Details
          </button>
        </form>
      </div>
    </div>
  </div>
  <div
    class="row d-flex justify-content-center"
    *ngIf="updateEmployee"
    style="margin-top: 5%; margin-bottom: 5%;"
  >
    <div class="col-md-6 col-sm-12">
      <div class="row">
        <div class="col-md-6 col-sm-4" (click)="getSchedule(employeeIDVal)">
          <button type="button" class="btn btn-primary">Get Schedule</button>
        </div>
        <div
          class="col-md-6 col-sm-6"
          (click)="openPasswordSection()"
          *ngIf="employeeFlag"
        >
          <button type="button" class="btn btn-primary">Change Password</button>
        </div>
        <div
          class="col-md-6 col-sm-6"
          (click)="updatePassword()"
          *ngIf="adminFlag"
        >
          <button type="button" class="btn btn-primary">
            Set Default Password
          </button>
        </div>
      </div>
    </div>
  </div>

  <div id="employee-schedule" class="container-fluid" *ngIf="scheduleSection">
    <h5 style="color: brown;">Employee Weekly Schedule</h5>
    <br /><br />
    <angular-slickgrid
      gridId="scheduleGrid"
      [columnDefinitions]="columnDefinitions"
      [gridOptions]="gridOptions"
      [dataset]="dataset"
      (onAngularGridCreated)="angularGridReady($event)"
    >
    </angular-slickgrid>
  </div>
  <div class="row d-flex justify-content-center" *ngIf="passwordSection">
    <div class="col-md-6 col-sm-12">
      <form
        [formGroup]="changePasswordForm"
        (ngSubmit)="updatePassword(changePasswordForm)"
        autocomplete="off"
      >
        <div class="col-md-6 col-sm-12">
          <div class="form-group">
            <label for="newPassword">Enter New Password</label>
            <input
              type="password"
              name="newPassword"
              formControlName="newPassword"
              autocomplete="off"
              class="form-control input-sm"
              placeholder="Enter New Password"
            />
          </div>
        </div>
        <div class="col-md-6 col-sm-12">
          <button
            type="submit"
            class="btn btn-success"
            style="margin-top: 10.5%;"
          >
            Update Password
          </button>
        </div>
      </form>
    </div>
  </div>
</div>
