<div class="full-title">
  <div class="container">
    <!-- Page Heading/Breadcrumbs -->
    <h1 class="mt-4 mb-3">Contact Us</h1>
    <div class="breadcrumb-main">
      <ol class="breadcrumb">
        <li class="breadcrumb-item">
          <a href="index.html">Home</a>
        </li>
        <li class="breadcrumb-item active">Contact Us</li>
      </ol>
    </div>
  </div>
</div>
<!-- End banner Area -->

<!-- Start Career Area -->

<section class="contact-page-area section-gap">
  <div class="container">
    <div class="row">
      <div class="col-lg-6 d-flex flex-column address-wrap">
        <div class="row" style="padding: 2%; margin-top: 12%;">
          For us to immediately review your case, please send us a message. We
          also accept inquiries and feedback on our home care services. You may
          use the contact form:
        </div>

        <div
          class="single-contact-address d-flex flex-row"
          style="margin-top: 5%;"
        >
          <div class="icon">
            <span class="fa fa-home"></span>
          </div>
          <div class="contact-details" style="padding-left: 5%; width: 100%;">
            <p>
              8850 Richmond Hwy. Ste 207A Alexandria, VA 22309
            </p>
          </div>
        </div>
        <div class="single-contact-address d-flex flex-row">
          <div class="icon">
            <span class="fa fa-home"></span>
          </div>
          <div class="contact-details" style="padding-left: 5%; width: 100%;">
            <p>
              8720 Georgia Avenue, Suite 704. Silver Spring, MD 20910
            </p>
          </div>
        </div>
        <div class="single-contact-address d-flex flex-row">
          <div class="icon">
            <span class="fa fa-phone"></span>
          </div>
          <div class="contact-details" style="padding-left: 5%; width: 100%;">
            <p>
              (571) 428-2398 / (571) 361-3076 / (301) 328-5704
            </p>
          </div>
        </div>
        <div class="single-contact-address d-flex flex-row">
          <div class="icon">
            <span class="fa fa-fax"></span>
          </div>
          <div class="contact-details" style="padding-left: 5%; width: 100%;">
            <p>
              (571) 428-2399 / (301) 328-5834
            </p>
          </div>
        </div>
        <div class="single-contact-address d-flex flex-row">
          <div class="icon">
            <span class="fa fa-envelope"></span>
          </div>
          <div class="contact-details" style="padding-left: 5%; width: 100%;">
            <p>Info@courtesyhs.com</p>
          </div>
        </div>
        <div class="map-wrap" style="width:100%; height: 300px;" id="map">
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3112.9807745582198!2d-77.13425628465501!3d38.71825037959863!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89b7ac44300258c1%3A0xf5affec97f8a092e!2s8850%20Richmond%20Hwy%20%23207A%2C%20Alexandria%2C%20VA%2022309!5e0!3m2!1sen!2sus!4v1586975409141!5m2!1sen!2sus"
            width="520"
            height="290"
            frameborder="0"
            style="border:0;"
            allowfullscreen=""
            aria-hidden="false"
            tabindex="0"
          ></iframe>
        </div>
      </div>
      <div class="col-lg-6">
        <h3
          *ngIf="message"
          [style.background-color]="status == '1' ? '#42A02A' : '#E95A46'"
          style="color: white; text-align: center; font-size: 15px;"
        >
          {{ message }}
          <a
            style="float: right; font-size: 12px; color: black; text-align: center;
            margin-right: 2%; cursor: pointer;"
            (click)="closeMessage()"
          >
            X
          </a>
        </h3>
        <br />
        <div class="jumbotron">
          <form
            [formGroup]="contactForm"
            (ngSubmit)="insertContact(contactForm)"
            autocomplete="off"
          >
            <div class="row">
              <div class="col-md-6 col-sm-12">
                <div class="form-group">
                  <label for="firstname">First Name</label>
                  <input
                    type="text"
                    name="firstname"
                    formControlName="firstname"
                    autocomplete="off"
                    class="form-control input-sm"
                    placeholder="First Name"
                  />
                </div>
              </div>
              <div class="col-md-6 col-sm-12">
                <div class="form-group">
                  <label for="lastname">Last Name</label>
                  <input
                    type="text"
                    name="lastname"
                    formControlName="lastname"
                    autocomplete="off"
                    class="form-control input-sm"
                    placeholder="Last Name"
                  />
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-5 col-sm-12">
                <div class="form-group">
                  <label for="contact">Phone Number</label>
                  <input
                    type="text"
                    name="contact"
                    formControlName="contact"
                    autocomplete="off"
                    class="form-control input-sm"
                    placeholder="Number"
                  />
                </div>
              </div>
              <div class="col-md-7 col-sm-12">
                <div class="form-group">
                  <label for="email">Email</label>
                  <input
                    type="text"
                    name="email"
                    formControlName="email"
                    autocomplete="off"
                    class="form-control input-sm"
                    placeholder="Email"
                  />
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-12 col-sm-12">
                <div class="form-group">
                  <label for="address">Address</label>
                  <input
                    type="text"
                    name="address"
                    formControlName="address"
                    autocomplete="off"
                    class="form-control input-sm"
                    placeholder="Address"
                  />
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-4 col-sm-12">
                <div class="form-group">
                  <label for="city">City</label>
                  <input
                    type="text"
                    name="city"
                    formControlName="city"
                    autocomplete="off"
                    class="form-control input-sm"
                    placeholder="City"
                  />
                </div>
              </div>
              <div class="col-md-4 col-sm-12">
                <div class="form-group">
                  <label for="state">State/Province</label>
                  <input
                    type="text"
                    name="state"
                    formControlName="state"
                    autocomplete="off"
                    class="form-control input-sm"
                    placeholder="State/Province"
                  />
                </div>
              </div>
              <div class="col-md-4 col-sm-12">
                <div class="form-group">
                  <label for="zip">Zip/Postal Code</label>
                  <input
                    type="text"
                    name="zip"
                    formControlName="zip"
                    autocomplete="off"
                    class="form-control input-sm"
                    placeholder="Zip/Postal Code"
                  />
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-12 col-sm-12">
                <label for="comment">Question/Comments</label>
                <textarea
                  name="comment"
                  formControlName="comment"
                  autocomplete="off"
                  class="form-control"
                  placeholder="Enter your Question/Comments"
                  rows="5"
                ></textarea>
              </div>
            </div>
            <button
              type="submit"
              class="btn btn-success"
              style="margin-top: 4%;"
            >
              Send Message
            </button>
          </form>
        </div>
      </div>
    </div>
  </div>
</section>
