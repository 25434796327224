<div class="full-title">
  <div class="container">
    <!-- Page Heading/Breadcrumbs -->
    <h1 class="mt-4 mb-3">Personal Care</h1>
    <div class="breadcrumb-main">
      <ol class="breadcrumb">
        <li class="breadcrumb-item">
          <a href="index.html">Home</a>
        </li>
        <li class="breadcrumb-item active">Personal Care</li>
      </ol>
    </div>
  </div>
</div>
<!-- End banner Area -->

<!-- Start Personal Care Area -->
<div class="item-pro">
  <div class="container">
    <!-- Portfolio Item Row -->
    <div class="row">
      <p style="text-align: center;">
        Home Health Aides at Courtesy Healthcare Services are licensed, bonded
        and insured – ready to be assigned and care for you at home. They are
        trained to take care of patients at home and are educated to administer
        the following procedures with precision.
      </p>
    </div>
    <div class="row" style="margin-top: 5%;">
      <div class="col-md-6">
        <img class="img-fluid" src="assets/img/courtesy/image_8.jpg" alt="" />
      </div>
      <div class="col-md-6">
        <h3 class="my-3">PERSONAL AND RESPITE CARE</h3>
        <ul>
          <li style="display: list-item;">
            <span>Personal care (bathing and personal hygiene)</span>
          </li>
          <li style="display: list-item;">
            <span
              >Assistance with dressing, toileting, eating, and/or ambulation
              range-of-motion exercises</span
            >
          </li>
          <li style="display: list-item;"><span>Meal Preparation</span></li>
          <li style="display: list-item;"><span>Laundry</span></li>
          <li style="display: list-item;"><span>Medication reminders</span></li>
          <li style="display: list-item;"><span>Meal preparation</span></li>
          <li style="display: list-item;">
            <span>Laundry and light housekeeping</span>
          </li>
          <li style="display: list-item;">
            <span>Monitoring vital signs</span>
          </li>
          <li style="display: list-item;">
            <span>Private Duty Home Care</span>
          </li>
          <li style="display: list-item;"><span>Live-In Care</span></li>
          <li style="display: list-item;"><span>24-Hour Home Care</span></li>
          <li><span>and more...</span></li>
        </ul>
      </div>
    </div>
    <div class="row" style="margin-top: 5%;">
      <div class="col-md-6">
        <h3 class="my-3">PRIVATE DUTY</h3>
        <ul>
          <li style="display: list-item;"><span>Bathing and Dressing</span></li>
          <li style="display: list-item;">
            <span>Meal Planning and Preparation</span>
          </li>
          <li style="display: list-item;">
            <span>Post-Amputation Assistance</span>
          </li>
          <li style="display: list-item;">
            <span>Accompany to Appointments and Shopping</span>
          </li>
          <li style="display: list-item;"><span>Hourly Care</span></li>
          <li style="display: list-item;"><span>Live-in Care</span></li>
          <li style="display: list-item;">
            <span>Companionship Services</span>
          </li>
          <li style="display: list-item;"><span>Respite Care</span></li>
        </ul>
      </div>
      <div class="col-md-6">
        <img class="img-fluid" src="assets/img/courtesy/pcare1.jpeg" alt="" />
      </div>
    </div>
    <div class="row" style="margin-top: 5%;">
      <div class="col-md-6">
        <img
          class="img-fluid"
          src="assets/img/courtesy/Companionship.jpg"
          alt=""
        />
      </div>
      <div class="col-md-6">
        <h3 class="my-3">COMPANIONSHIP</h3>
        <ul>
          <li style="display: list-item;">
            <span>Assisting with daily living tasks</span>
          </li>
          <li style="display: list-item;">
            <span>Assisting with homemaking tasks laundry and ironing</span>
          </li>
          <li style="display: list-item;"><span>Bed-Side Care</span></li>
          <li style="display: list-item;"><span>Changing linens</span></li>
          <li style="display: list-item;">
            <span
              >Escorted Transportation to medical appointments & religious
              services</span
            >
          </li>
          <li style="display: list-item;"><span>Exercise Assistance</span></li>
          <li style="display: list-item;">
            <span>Meal planning and cooking</span>
          </li>
          <li style="display: list-item;"><span>Medication reminders</span></li>
          <li style="display: list-item;"><span>Personal Care</span></li>
          <li style="display: list-item;">
            <span>Picking up your medications and prescriptions</span>
          </li>
        </ul>
      </div>
    </div>
    <div class="row" style="margin-top: 5%;">
      <p style="text-align: center;">
        If you or your loved one is experiencing health issues that need
        round-the-clock care to manage properly, please think of how Courtesy
        Healthcare Services can be of assistance. Apart from live-in care, we
        also offer hourly or overnight private duty care services depending on
        your needs. Please send us a message to get started.
      </p>
    </div>
  </div>
  <!-- /.container -->
</div>
