import { Component, OnInit } from '@angular/core';
import { ApiService } from '../api.service';
import { environment } from '../../environments/environment';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {
  loginbtn: boolean;
  logoutbtn: boolean;
  userRole = null;
  isUserAdmin = false;

  constructor(private dataService: ApiService) {
    dataService.getLoggedInName.subscribe(name => this.changeName(name));
    if (this.dataService.isLoggedIn()) {
      this.userRole = this.dataService.getToken();
      this.loginbtn = false;
      this.logoutbtn = true;
      if (this.userRole == 'ADMIN') {
        this.isUserAdmin = true;
      }
    } else {
      this.loginbtn = true;
      this.logoutbtn = false;
      this.isUserAdmin = false;
    }
  }

  ngOnInit() {}

  private changeName(name: boolean): void {
    this.logoutbtn = name;
    this.loginbtn = !name;
  }

  logout() {
    this.dataService.deleteToken();
    window.location.href = 'http://www.courtesyhs.com/';
  }
}
