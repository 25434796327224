<div class="full-title">
  <div class="container">
    <!-- Page Heading/Breadcrumbs -->
    <h1 class="mt-4 mb-3">Companionship</h1>
    <div class="breadcrumb-main">
      <ol class="breadcrumb">
        <li class="breadcrumb-item">
          <a href="index.html">Home</a>
        </li>
        <li class="breadcrumb-item active">Companionship</li>
      </ol>
    </div>
  </div>
</div>
<!-- End banner Area -->

<!-- Start Companionship Area -->
<div class="item-pro">
  <div class="container">
    <!-- Portfolio Item Row -->
    <div class="row">
      <div class="col-md-8">
        <img
          class="img-fluid"
          src="assets/img/courtesy/companionship_big.jpg"
          alt=""
        />
      </div>
      <div class="col-md-4">
        <p>
          Keep your choice to stay at home. You can still remain in good health
          with the help of Private Duty Care professionals from Courtesy
          Healthcare Services. Private Duty Care is ideal for patients who need
          live-in care services.
        </p>
        <h3 class="my-3">COMPANIONSHIP</h3>
        <ul>
          <li><span>Assisting with daily living tasks</span></li>
          <li>
            <span>Assisting with homemaking tasks laundry and ironing</span>
          </li>
          <li><span>Bed-Side Care</span></li>
          <li><span>Changing linens</span></li>
          <li>
            <span
              >Escorted Transportation to medical appointments & religious
              services</span
            >
          </li>
          <li><span>Exercise Assistance</span></li>
          <li><span>Meal planning and cooking</span></li>
          <li><span>Medication reminders</span></li>
          <li><span>Personal Care</span></li>
          <li><span>Picking up your medications and prescriptions</span></li>
        </ul>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-12">
        <p class="mt-3">
          If you or your loved one is experiencing health issues that need
          round-the-clock care to manage properly, please think of how Courtesy
          Healthcare Services can be of assistance. Apart from live-in care, we
          also offer hourly or overnight private duty care services depending on
          your needs. Please send us a message to get started.
        </p>
      </div>
    </div>
  </div>
  <!-- /.container -->
</div>
