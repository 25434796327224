import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomeComponent } from './home/home.component';
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { AboutComponent } from './about/about.component';
import { CareersComponent } from './careers/careers.component';
import { CompanionshipComponent } from './companionship/companionship.component';
import { ContactComponent } from './contact/contact.component';
import { CovidComponent } from './covid/covid.component';
import { FaqComponent } from './faq/faq.component';
import { HireUsComponent } from './hire-us/hire-us.component';
import { PersonalCareComponent } from './personal-care/personal-care.component';
import { PrivateDutyComponent } from './private-duty/private-duty.component';
import { ReferralsComponent } from './referrals/referrals.component';
import { LoginComponent } from './login/login.component';
import { AdminComponent } from './admin/admin.component';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { PatientdetailsComponent } from './patientdetails/patientdetails.component';
import { EmployeedetailsComponent } from './employeedetails/employeedetails.component';
import { AngularSlickgridModule, CollectionService } from 'angular-slickgrid';
import { RestrictKeywordValidatorDirective } from './restrict-keyword-validator';
import { SSNPipe } from './ssn-pipe';
import { OurProcessComponent } from './our-process/our-process.component';
import { SkilledCareComponent } from './skilled-care/skilled-care.component';
import { MedicalEquipmentsComponent } from './medical-equipments/medical-equipments.component';

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    HeaderComponent,
    FooterComponent,
    AboutComponent,
    CareersComponent,
    CompanionshipComponent,
    ContactComponent,
    CovidComponent,
    FaqComponent,
    HireUsComponent,
    PersonalCareComponent,
    PrivateDutyComponent,
    ReferralsComponent,
    LoginComponent,
    AdminComponent,
    PatientdetailsComponent,
    EmployeedetailsComponent,
    RestrictKeywordValidatorDirective,
    SSNPipe,
    OurProcessComponent,
    SkilledCareComponent,
    MedicalEquipmentsComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    NgMultiSelectDropDownModule,
    AngularSlickgridModule.forRoot()
  ],
  exports: [AngularSlickgridModule],
  providers: [CollectionService],
  bootstrap: [AppComponent]
})
export class AppModule {}
