<div class="full-title">
  <div class="container">
    <!-- Page Heading/Breadcrumbs -->
    <h1 class="mt-4 mb-3">Patient Details</h1>
    <div class="breadcrumb-main">
      <ol class="breadcrumb">
        <li class="breadcrumb-item">
          <a href="index.html">Home</a>
        </li>
        <li class="breadcrumb-item active">Patient Details</li>
      </ol>
    </div>
  </div>
</div>

<!-- Start About Us Area -->
<div class="container" style="padding-bottom: 3%;">
  <div class="row d-flex justify-content-center" style="margin-bottom: 5%;">
    <div class="col-lg-10">
      <h3
        *ngIf="message"
        [style.background-color]="status == '1' ? '#42A02A' : '#E95A46'"
        style="color: white; text-align: center; font-size: 15px;"
      >
        {{ message }}
        <a
          style="float: right; font-size: 12px; color: black; text-align: center; margin-right: 2%; cursor: pointer;"
          (click)="closeMessage()"
        >
          X
        </a>
      </h3>
      <br />
      <form
        [formGroup]="searchPatientForm"
        (ngSubmit)="getPatientDetails(searchPatientForm)"
        autocomplete="off"
      >
        <div class="row d-flex justify-content-center">
          <div class="col-md-4 col-sm-12">
            <div class="form-group">
              <label for="searchPatientID">Enter Patient ID</label>
              <input
                type="text"
                name="searchPatientID"
                formControlName="searchPatientID"
                autocomplete="off"
                class="form-control input-sm"
                placeholder="Enter Patient ID"
              />
            </div>
          </div>
          <div class="col-md-4 col-sm-12">
            <div class="form-group">
              <label for="searchPatientSSN">Enter Patient SSN</label>
              <input
                type="text"
                name="searchPatientSSN"
                formControlName="searchPatientSSN"
                autocomplete="off"
                class="form-control input-sm"
                placeholder="Enter Patient SSN"
                (blur)="onBlurSSN($event.target.value)"
              />
            </div>
          </div>
          <div class="col-md-4 col-sm-12">
            <button
              type="submit"
              class="btn btn-success"
              style="margin-top: 10.5%;"
            >
              Search Patient
            </button>
          </div>
        </div>
      </form>
    </div>
  </div>

  <div *ngIf="patientDetailsFlag">
    <div style=" border: 1px solid #ddd; margin-top: 1em; ">
      <h1 style="font-size: 12px; text-align: center;">
        <span
          style="display: inline; border: 1px solid #ddd; background: #fff; padding: 5px 10px; position: relative; top: -1em;"
        >
          Personal Details
        </span>
      </h1>
      <div class="row" style="margin: 2%;">
        <div class="col-md-3 col-sm-12">
          <strong>Name:</strong> {{ patientDetails.firstname }}
          {{ patientDetails.lastname }}
        </div>
        <div class="col-md-2 col-sm-12">
          <strong>Age:</strong> {{ patientDetails.age }}
        </div>
        <div class="col-md-2 col-sm-12">
          <strong>SSN:</strong> {{ patientDetails.ssn }}
        </div>
        <div class="col-md-2 col-sm-12">
          <strong>Contact:</strong> {{ patientDetails.contact }}
        </div>
        <div class="col-md-3 col-sm-12">
          <strong>Email ID:</strong> {{ patientDetails.email }}
        </div>
      </div>
      <div class="row" style="margin: 2%;">
        <div class="col-md-12 col-sm-12">
          <strong>Address:</strong> {{ patientDetails.address }},
          {{ patientDetails.city }}, {{ patientDetails.state }},
          {{ patientDetails.zip }}
        </div>
      </div>
    </div>
    <div style=" border: 1px solid #ddd; margin-top: 3em; ">
      <h1 style="font-size: 12px; text-align: center;">
        <span
          style="display: inline; border: 1px solid #ddd; background: #fff; padding: 5px 10px; position: relative; top: -1em;"
        >
          Emergency Contact
        </span>
      </h1>
      <div class="row" style="margin: 2%;">
        <div class="col-md-4 col-sm-12">
          <strong>Contact Name:</strong>
          {{ patientDetails.emergencyContactName }}
        </div>
        <div class="col-md-4 col-sm-12">
          <strong>Contact Relation:</strong>
          {{ patientDetails.emergencyContactRelation }}
        </div>
        <div class="col-md-4 col-sm-12">
          <strong>Emergency Number:</strong>
          {{ patientDetails.emergencyContactNumber }}
        </div>
      </div>
    </div>
    <div style=" border: 1px solid #ddd; margin-top: 3em; ">
      <h1 style="font-size: 12px; text-align: center;">
        <span
          style="display: inline; border: 1px solid #ddd; background: #fff; padding: 5px 10px; position: relative; top: -1em;"
        >
          Medical Details
        </span>
      </h1>
      <div class="row" style="margin: 2%;">
        <div class="col-md-4 col-sm-12">
          <strong>Consulting Doctor:</strong> {{ patientDetails.doctor }}
        </div>
        <div class="col-md-4 col-sm-12">
          <strong>Associated Nurse:</strong> {{ patientDetails.nurse }}
        </div>
        <div class="col-md-4 col-sm-12">
          <strong>Services Availed:</strong> {{ patientDetails.services }}
        </div>
      </div>
      <div class="row" style="margin: 2%;">
        <div class="col-md-4 col-sm-12">
          <strong>Insurance Type:</strong> {{ patientDetails.insuranceType }}
        </div>
        <div class="col-md-6 col-sm-12">
          <strong>Private Insurance (If Any):</strong>
          {{ patientDetails.privateInsurance }}
        </div>
      </div>
      <div class="row" style="margin: 2%;">
        <div class="col-md-12 col-sm-12">
          <strong>Diagnoses: </strong> {{ patientDetails.diagnoses }}
        </div>
      </div>
    </div>
    <div style=" border: 1px solid #ddd; margin-top: 3em; ">
      <h1 style="font-size: 12px; text-align: center;">
        <span
          style="display: inline; border: 1px solid #ddd; background: #fff; padding: 5px 10px; position: relative; top: -1em;"
        >
          Medical History
        </span>
      </h1>
      <div class="row" style="margin: 2%;">
        <div class="col-md-12 col-sm-12" style="white-space:pre-wrap;">
          {{ patientDetails.history }}
        </div>
      </div>
    </div>
  </div>

  <div class="row d-flex justify-content-center" *ngIf="updatePatient">
    <div class="col-md-10">
      <h3>Update Patient</h3>
      <div class="jumbotron">
        <form
          [formGroup]="patientForm"
          (ngSubmit)="updatePatientDetails(patientForm)"
          autocomplete="off"
        >
          <div class="row">
            <div class="col-md-6 col-sm-12">
              <div class="form-group">
                <label for="patientID">Patient ID</label>
                <input
                  type="text"
                  name="patientID"
                  formControlName="patientID"
                  autocomplete="off"
                  class="form-control input-sm"
                  placeholder="Patient ID"
                  disabled
                />
              </div>
            </div>
            <div class="col-md-6 col-sm-12">
              <div class="form-group">
                <label for="ssn">SSN</label>
                <input
                  type="text"
                  name="ssn"
                  formControlName="ssn"
                  autocomplete="off"
                  class="form-control input-sm"
                  placeholder="SSN"
                  disabled
                />
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-6 col-sm-12">
              <div class="form-group">
                <label for="firstname">First Name</label>
                <input
                  type="text"
                  name="firstname"
                  formControlName="firstname"
                  autocomplete="off"
                  class="form-control input-sm"
                  placeholder="First Name"
                />
              </div>
            </div>
            <div class="col-md-6 col-sm-12">
              <div class="form-group">
                <label for="lastname">Last Name</label>
                <input
                  type="text"
                  name="lastname"
                  formControlName="lastname"
                  autocomplete="off"
                  class="form-control input-sm"
                  placeholder="Last Name"
                />
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-3 col-sm-12">
              <div class="form-group">
                <label for="age">Age</label>
                <input
                  type="text"
                  name="age"
                  formControlName="age"
                  autocomplete="off"
                  class="form-control input-sm"
                  placeholder="Age"
                />
              </div>
            </div>
            <div class="col-md-4 col-sm-12">
              <div class="form-group">
                <label for="contact">Phone Number</label>
                <input
                  type="text"
                  name="contact"
                  formControlName="contact"
                  autocomplete="off"
                  class="form-control input-sm"
                  placeholder="Number"
                />
              </div>
            </div>
            <div class="col-md-5 col-sm-12">
              <div class="form-group">
                <label for="email">Email</label>
                <input
                  type="text"
                  name="email"
                  formControlName="email"
                  autocomplete="off"
                  class="form-control input-sm"
                  placeholder="Email"
                />
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-4 col-sm-12">
              <div class="form-group">
                <label for="emergencyContactName">Emergency Contact Name</label>
                <input
                  type="text"
                  name="emergencyContactName"
                  formControlName="emergencyContactName"
                  autocomplete="off"
                  class="form-control input-sm"
                  placeholder="Emergency Contact Name"
                />
              </div>
            </div>
            <div class="col-md-4 col-sm-12">
              <div class="form-group">
                <label for="emergencyContactRelation"
                  >Emergency Contact Relation</label
                >
                <input
                  type="text"
                  name="emergencyContactRelation"
                  formControlName="emergencyContactRelation"
                  autocomplete="off"
                  class="form-control input-sm"
                  placeholder="Emergency Contact Relation"
                />
              </div>
            </div>
            <div class="col-md-4 col-sm-12">
              <div class="form-group">
                <label for="emergencyContactNumber"
                  >Emergency Contact Number</label
                >
                <input
                  type="text"
                  name="emergencyContactNumber"
                  formControlName="emergencyContactNumber"
                  autocomplete="off"
                  class="form-control input-sm"
                  placeholder="Emergency Contact Number"
                />
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12 col-sm-12">
              <div class="form-group">
                <label for="address">Address</label>
                <input
                  type="text"
                  name="address"
                  formControlName="address"
                  autocomplete="off"
                  class="form-control input-sm"
                  placeholder="Address"
                />
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-4 col-sm-12">
              <div class="form-group">
                <label for="city">City</label>
                <input
                  type="text"
                  name="city"
                  formControlName="city"
                  autocomplete="off"
                  class="form-control input-sm"
                  placeholder="City"
                />
              </div>
            </div>
            <div class="col-md-4 col-sm-12">
              <div class="form-group">
                <label for="state">State/Province</label>
                <input
                  type="text"
                  name="state"
                  formControlName="state"
                  autocomplete="off"
                  class="form-control input-sm"
                  placeholder="State/Province"
                />
              </div>
            </div>
            <div class="col-md-4 col-sm-12">
              <div class="form-group">
                <label for="zip">Zip/Postal Code</label>
                <input
                  type="text"
                  name="zip"
                  formControlName="zip"
                  autocomplete="off"
                  class="form-control input-sm"
                  placeholder="Zip/Postal Code"
                />
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12 col-sm-12">
              <label for="diagnoses">Diagnoses</label>
              <textarea
                name="diagnoses"
                formControlName="diagnoses"
                autocomplete="off"
                class="form-control"
                placeholder="Enter Diagnoses"
                rows="5"
              ></textarea>
            </div>
          </div>
          <div class="row">
            <div class="col-md-6 col-sm-12">
              <div class="form-group">
                <label for="doctor">Consulting Doctor</label>
                <input
                  type="text"
                  name="doctor"
                  formControlName="doctor"
                  autocomplete="off"
                  class="form-control input-sm"
                  placeholder="Consulting Doctor"
                />
              </div>
            </div>
            <div class="col-md-6 col-sm-12">
              <div class="form-group">
                <label for="nurseID">Associated Nurse ID</label>
                <input
                  type="text"
                  name="nurseID"
                  formControlName="nurseID"
                  autocomplete="off"
                  class="form-control input-sm"
                  placeholder="Associated Nurse ID"
                />
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12 col-sm-12">
              <label for="services">Services</label>
              <ng-multiselect-dropdown
                name="services"
                [placeholder]="'Select Services'"
                [data]="servicesList"
                formControlName="services"
                [settings]="dropdownSettings"
              >
              </ng-multiselect-dropdown>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12 col-sm-12">
              <label style="margin-top: 2%;">Insurance Type</label>
            </div>
            <div class="col-md-3 col-sm-12">
              <div class="form-group">
                <input
                  id="medicaid"
                  type="radio"
                  formControlName="insuranceType"
                  value="Medicaid"
                  name="insuranceType"
                />
                <label for="medicaid">Medicaid</label>
              </div>
            </div>
            <div class="col-md-3 col-sm-12">
              <div class="form-group">
                <input
                  id="lti"
                  type="radio"
                  formControlName="insuranceType"
                  value="Long Term Insurance"
                  name="insuranceType"
                />
                <label for="lti">Long Term Insurance</label>
              </div>
            </div>
            <div class="col-md-3 col-sm-12">
              <div class="form-group">
                <input
                  id="private"
                  type="radio"
                  formControlName="insuranceType"
                  value="Private"
                  name="insuranceType"
                />
                <label for="private">Private Insurance</label>
              </div>
            </div>
            <div class="col-md-3 col-sm-12">
              <div class="form-group">
                <input
                  id="self"
                  type="radio"
                  formControlName="insuranceType"
                  value="Self"
                  name="insuranceType"
                />
                <label for="self">Self-Pay</label>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12 col-sm-12">
              <div class="form-group">
                <label for="privateInsurance">Private Insurance</label>
                <input
                  type="text"
                  name="privateInsurance"
                  formControlName="privateInsurance"
                  autocomplete="off"
                  class="form-control input-sm"
                  placeholder="Private Insurance"
                />
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12 col-sm-12">
              <label for="history">History</label>
              <textarea
                name="history"
                formControlName="history"
                autocomplete="off"
                class="form-control"
                placeholder="Enter History"
                rows="5"
              ></textarea>
            </div>
          </div>
          <button type="submit" class="btn btn-success" style="margin-top: 4%;">
            Update Patient Details
          </button>
        </form>
      </div>
    </div>
  </div>
</div>
