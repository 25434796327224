import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-skilled-care',
  templateUrl: './skilled-care.component.html',
  styleUrls: ['./skilled-care.component.css']
})
export class SkilledCareComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
