<div class="full-title">
  <div class="container">
    <!-- Page Heading/Breadcrumbs -->
    <h1 class="mt-4 mb-3">Login</h1>
    <div class="breadcrumb-main">
      <ol class="breadcrumb">
        <li class="breadcrumb-item">
          <a href="index.html">Home</a>
        </li>
        <li class="breadcrumb-item active">Login</li>
      </ol>
    </div>
  </div>
</div>

<div class="container">
  <div class="row d-flex justify-content-center">
    <div class="col-md-6">
      <h3
        *ngIf="message"
        style="background-color: #EA735A; color: white; text-align: center; font-size: 15px;"
      >
        {{ message }}
        <a
          style="float: right; font-size: 12px; color: black; text-align: center;
         margin-right: 2%; cursor: pointer;"
          (click)="reset()"
        >
          X
        </a>
      </h3>
      <br />
      <div class="jumbotron">
        <form
          [formGroup]="loginForm"
          (ngSubmit)="postdata(loginForm)"
          autocomplete="off"
        >
          <div class="form-group">
            <label for="username">Username</label>
            <input
              type="text"
              name="username"
              formControlName="username"
              autocomplete="off"
              class="form-control input-sm"
              placeholder="Username"
            />
          </div>
          <div class="form-group">
            <label for="Password">Password</label>
            <input
              type="password"
              name="Password"
              formControlName="password"
              autocomplete="off"
              class="form-control input-sm"
              placeholder="Password"
            />
          </div>
          <button type="submit" class="btn btn-success">Login</button>
        </form>
      </div>
    </div>
  </div>
</div>
