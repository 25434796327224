import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { Contact } from '../users';
import { ApiService } from '../api.service';
import { Router } from '@angular/router';
import { first } from 'rxjs/operators';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.css']
})
export class ContactComponent implements OnInit {
  contactForm: FormGroup;
  contactData: Contact;
  message: string;
  status: string;

  constructor(
    private fb: FormBuilder,
    private dataService: ApiService,
    private router: Router
  ) {}

  ngOnInit() {
    this.contactForm = this.fb.group({
      firstname: [''],
      lastname: [''],
      contact: [''],
      email: [''],
      address: [''],
      city: [''],
      state: [''],
      zip: [''],
      comment: ['']
    });
  }

  insertContact(contactForm) {
    this.contactData = {
      firstname: contactForm.value.firstname,
      lastname: contactForm.value.lastname,
      contact: contactForm.value.contact,
      email: contactForm.value.email,
      address: contactForm.value.address,
      city: contactForm.value.city,
      state: contactForm.value.state,
      zip: contactForm.value.zip,
      comment: contactForm.value.comment
    };

    this.dataService
      .addEnquiry(this.contactData)
      .pipe(first())
      .subscribe(
        data => {
          const redirect = this.dataService.redirectUrl
            ? this.dataService.redirectUrl
            : '/contact';
          this.router.navigate([redirect]);
          this.contactForm.reset();
          this.status = data.success;
          this.message = 'Enquiry ' + data.msg;
        },
        error => {
          this.router.navigate(['/contact']);
          this.contactForm.reset();
          this.message = 'Some Error Occurred.';
        }
      );
  }

  closeMessage() {
    this.message = null;
  }
}
