<div class="full-title">
  <div class="container">
    <!-- Page Heading/Breadcrumbs -->
    <h1 class="mt-4 mb-3">Durable Medical Equipment</h1>
    <div class="breadcrumb-main">
      <ol class="breadcrumb">
        <li class="breadcrumb-item">
          <a href="index.html">Home</a>
        </li>
        <li class="breadcrumb-item active">Durable Medical Equipment</li>
      </ol>
    </div>
  </div>
</div>
<!-- End banner Area -->

<!-- Start Private Duty Area -->
<div class="item-pro">
  <div class="container">
    <!-- Portfolio Item Row -->
    <div class="row" style="justify-content: center;">
      <h3 class="my-3">Coordination of Home Medical Equipment Needs</h3>
      <p style="text-align: center;">
        Should patients require medical equipment, Courtesy Healthcare Services
        can arrange for items such as hospital beds, oxygen tanks, wheelchairs,
        lifts or other rehabilitative equipment.
      </p>
    </div>
  </div>
  <!-- /.container -->
</div>
