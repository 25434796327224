import { Component, OnInit } from '@angular/core';

import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ApiService } from '../api.service';
import { Router } from '@angular/router';
import { first } from 'rxjs/operators';
import { LoginUser, Employee, Patient } from '../users';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { RestrictKeywordValidatorDirective } from '../restrict-keyword-validator';
import {
  AngularGridInstance,
  Column,
  FieldType,
  Formatter,
  GridOption
} from 'angular-slickgrid';

@Component({
  selector: 'app-employeedetails',
  templateUrl: './employeedetails.component.html',
  styleUrls: ['./employeedetails.component.css']
})
export class EmployeedetailsComponent implements OnInit {
  searchEmployeeForm: FormGroup;
  employeeForm: FormGroup;
  changePasswordForm: FormGroup;
  employeeDetailsFlag = false;
  updateEmployee = false;
  adminFlag = false;
  employeeFlag = false;
  scheduleSection = false;
  passwordSection = false;
  userRole = null;
  message: string;
  status: string;
  employeeData: Employee;
  employeeIDVal: '';
  employeeFirstName: '';
  ssnVal: string;

  employeeDetails = {
    address: '',
    city: '',
    contact: '',
    email: '',
    firstname: '',
    lastname: '',
    employeeID: '',
    ssn: '',
    state: '',
    zip: '',
    role: '',
    qualification: '',
    experience: ''
  };

  columnDefinitions: Column[];
  gridOptions: GridOption;
  dataset: object;
  angularGrid: AngularGridInstance;
  customButtonFormatter: Formatter = (
    row: number,
    cell: number,
    value: any,
    columnDef: Column,
    dataContext: any,
    grid: any
  ) => {
    return `<span style="margin-left: 5px;"> <button class="btn btn-xs btn-default"> 
            <i class="fa ${value ? 'fa-check-circle' : 'fa-circle-thin'} fa-lg"
            style="vertical-align: 10%; color: ${
              value ? 'black' : 'lavender'
            }"></i> </button> </span>`;
  };

  constructor(
    private fb: FormBuilder,
    private dataService: ApiService,
    private router: Router
  ) {}

  ngOnInit() {
    this.userRole = this.dataService.getToken();
    this.initgrid();
    this.searchEmployeeForm = this.fb.group({
      searchEmployeeID: [''],
      searchEmployeeSSN: ['']
    });

    this.changePasswordForm = this.fb.group({
      newPassword: ['']
    });

    this.employeeForm = this.fb.group({
      employeeID: [''],
      ssn: [''],
      role: [''],
      firstname: [''],
      lastname: [''],
      contact: [''],
      email: [''],
      address: [''],
      city: [''],
      state: [''],
      zip: [''],
      qualification: [''],
      experience: ['']
    });
  }

  getEmployeeDetails(searchEmployeeForm) {
    this.dataService
      .getEmployeeDetails(
        searchEmployeeForm.value.searchEmployeeID,
        searchEmployeeForm.value.searchEmployeeSSN
      )
      .pipe(first())
      .subscribe(
        data => {
          this.employeeIDVal = data.employeeID;
          this.employeeFirstName = data.firstname;
          if (this.userRole) {
            this.employeeDetailsFlag = false;
            this.updateEmployee = true;
            if (this.userRole == 'ADMIN') {
              this.employeeFlag = false;
              this.adminFlag = true;
            } else {
              this.adminFlag = false;
              this.employeeFlag = true;
            }
            this.employeeForm.patchValue({
              employeeID: data.employeeID,
              ssn: data.ssn,
              firstname: data.firstname,
              lastname: data.lastname,
              contact: data.contact,
              email: data.email,
              address: data.address,
              city: data.city,
              state: data.state,
              zip: data.zip,
              qualification: data.qualification,
              experience: data.experience
            });
          } else {
            this.updateEmployee = false;
            this.employeeDetailsFlag = true;
            this.employeeDetails = {
              address: data.address,
              city: data.city,
              contact: data.contact,
              email: data.email,
              firstname: data.firstname,
              lastname: data.lastname,
              employeeID: data.employeeID,
              ssn: data.ssn,
              state: data.state,
              zip: data.zip,
              role: data.role,
              qualification: data.qualification,
              experience: data.experience
            };
          }
          const redirect = this.dataService.redirectUrl
            ? this.dataService.redirectUrl
            : '/employee-details';
          this.router.navigate([redirect]);
        },
        error => {
          this.router.navigate(['/employee-details']);
          this.searchEmployeeForm.reset();
          this.status = '0';
          this.message = 'Employee not found.';
        }
      );
  }

  updateEmployeeDetails(employeeForm) {
    this.employeeData = {
      employeeID: employeeForm.value.employeeID,
      ssn: employeeForm.value.ssn,
      firstname: employeeForm.value.firstname,
      lastname: employeeForm.value.lastname,
      contact: employeeForm.value.contact,
      email: employeeForm.value.email,
      address: employeeForm.value.address,
      city: employeeForm.value.city,
      state: employeeForm.value.state,
      zip: employeeForm.value.zip,
      role: employeeForm.value.role,
      qualification: employeeForm.value.qualification,
      experience: employeeForm.value.experience
    };

    this.dataService
      .updateEmployee(this.employeeData)
      .pipe(first())
      .subscribe(
        data => {
          const redirect = this.dataService.redirectUrl
            ? this.dataService.redirectUrl
            : '/patient-details';
          this.router.navigate([redirect]);
          this.employeeForm.reset();
          this.status = data.success;
          this.message = 'Employee ' + data.msg;
        },
        error => {
          this.router.navigate(['/admin']);
          this.employeeForm.reset();
          this.message = 'Some Error Occurred.';
        }
      );
  }

  getSchedule(employeeIDVal) {
    this.passwordSection = false;
    this.scheduleSection = true;
    this.dataService
      .getEmployeeSchedule(employeeIDVal)
      .pipe(first())
      .subscribe(
        data => {
          for (let i = 0; i < data.length; i++) {
            data[i]['Monday'] = data[i]['Monday'] == 1 ? true : false;
            data[i]['Tuesday'] = data[i]['Tuesday'] == 1 ? true : false;
            data[i]['Wednesday'] = data[i]['Wednesday'] == 1 ? true : false;
            data[i]['Thursday'] = data[i]['Thursday'] == 1 ? true : false;
            data[i]['Friday'] = data[i]['Friday'] == 1 ? true : false;
            data[i]['Saturday'] = data[i]['Saturday'] == 1 ? true : false;
            data[i]['Sunday'] = data[i]['Sunday'] == 1 ? true : false;
          }
          this.dataset = this.updateDataSet(data);
          const redirect = this.dataService.redirectUrl
            ? this.dataService.redirectUrl
            : '/employee-details';
          this.router.navigate([redirect]);
        },
        error => {
          this.router.navigate(['/admin']);
          this.employeeForm.reset();
          this.message = 'Some Error Occurred.';
        }
      );
  }

  initgrid() {
    this.columnDefinitions = [
      {
        id: 'timing',
        name: 'Timings',
        field: 'timing',
        type: FieldType.string,
        filterable: true,
        width: 40
      },
      {
        id: 'Monday',
        name: 'Monday',
        field: 'Monday',
        type: FieldType.boolean,
        filterable: true,
        width: 40,
        formatter: this.customButtonFormatter,
        onCellClick: (e, args) => {
          this.toggleCompletedProperty(args);
        }
      },
      {
        id: 'Tuesday',
        name: 'Tuesday',
        field: 'Tuesday',
        type: FieldType.boolean,
        filterable: true,
        width: 40,
        formatter: this.customButtonFormatter,
        onCellClick: (e, args) => {
          this.toggleCompletedProperty(args);
        }
      },
      {
        id: 'Wednesday',
        name: 'Wednesday',
        field: 'Wednesday',
        type: FieldType.boolean,
        filterable: true,
        width: 40,
        formatter: this.customButtonFormatter,
        onCellClick: (e, args) => {
          this.toggleCompletedProperty(args);
        }
      },
      {
        id: 'Thursday',
        name: 'Thursday',
        field: 'Thursday',
        type: FieldType.boolean,
        filterable: true,
        width: 40,
        formatter: this.customButtonFormatter,
        onCellClick: (e, args) => {
          this.toggleCompletedProperty(args);
        }
      },
      {
        id: 'Friday',
        name: 'Friday',
        field: 'Friday',
        type: FieldType.boolean,
        filterable: true,
        width: 40,
        formatter: this.customButtonFormatter,
        onCellClick: (e, args) => {
          this.toggleCompletedProperty(args);
        }
      },
      {
        id: 'Saturday',
        name: 'Saturday',
        field: 'Saturday',
        type: FieldType.boolean,
        filterable: true,
        width: 40,
        formatter: this.customButtonFormatter,
        onCellClick: (e, args) => {
          this.toggleCompletedProperty(args);
        }
      },
      {
        id: 'Sunday',
        name: 'Sunday',
        field: 'Sunday',
        type: FieldType.boolean,
        filterable: true,
        width: 40,
        formatter: this.customButtonFormatter,
        onCellClick: (e, args) => {
          this.toggleCompletedProperty(args);
        }
      }
    ];

    this.gridOptions = {
      autoResize: {
        containerId: 'employee-schedule',
        sidePadding: 15
      },
      enableFiltering: true,
      enableAutoResize: true,
      autoHeight: true
    };
  }

  angularGridReady(angularGrid: AngularGridInstance) {
    this.angularGrid = angularGrid;
  }

  updateDataSet(data: any) {
    data.map(e => {
      e.id = e.timing;
    });
    return data;
  }

  toggleCompletedProperty(args) {
    const itemName = args.columnDef.id;
    const item = args && args.dataContext;
    if (typeof item === 'object') {
      item[itemName] = !item[itemName];
      const dataContext = args.dataContext;
      this.dataService
        .updateSchedule(
          this.employeeIDVal,
          dataContext.timing,
          itemName,
          dataContext[itemName]
        )
        .pipe(first())
        .subscribe(data => {
          const redirect = this.dataService.redirectUrl
            ? this.dataService.redirectUrl
            : '/employee-details';
          this.router.navigate([redirect]);
          if (data.success == '1') {
            this.angularGrid.gridService.updateItemById(item.id, item, {
              highlightRow: false
            });
          }
        });
    }
  }

  openPasswordSection() {
    this.scheduleSection = false;
    this.passwordSection = true;
  }

  updatePassword(changePasswordForm = null) {
    let password = '';
    if (changePasswordForm) {
      password = changePasswordForm.value.newPassword;
    } else {
      password =
        this.employeeIDVal + '_' + this.employeeFirstName.toLowerCase();
    }
    this.dataService
      .changePassword(this.employeeIDVal, password)
      .pipe(first())
      .subscribe(
        data => {
          const redirect = this.dataService.redirectUrl
            ? this.dataService.redirectUrl
            : '/employee-details';
          this.router.navigate([redirect]);
          this.status = data.success;
          this.message = 'Password ' + data.msg;
          this.changePasswordForm.reset();
        },
        error => {
          this.router.navigate(['/employee-details']);
          this.changePasswordForm.reset();
          this.message = 'Some Error Occurred.';
        }
      );
  }

  onBlurSSN(value: string) {
    this.ssnVal = this.searchEmployeeForm.get('searchEmployeeSSN').value;
    this.searchEmployeeForm
      .get('searchEmployeeSSN')
      .setValue(this.secureSSNFormat(value));
  }

  secureSSNFormat(inputVal) {
    var retVal = inputVal;
    if (inputVal == '') return (retVal = '');
    // if (topFrame.cnfParam("3413") == "MFF") {
    if (inputVal.length == 9) retVal = 'XXX-XX-' + inputVal.substr(5, 4);
    else alert('enter valid ssn');
    // }
    return retVal;
  }

  ValidSSNOnMasking(CorrectSSN, ValueIn) {
    var OriginalValue = CorrectSSN;
    return (
      ValueIn.substr(0, 3) +
      '-' +
      ValueIn.substr(3, 2) +
      '-' +
      ValueIn.substr(5)
    );
  }

  closeMessage() {
    this.message = null;
  }
}
