import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { Career } from '../users';
import { ApiService } from '../api.service';
import { Router } from '@angular/router';
import { first } from 'rxjs/operators';
import { RestrictKeywordValidatorDirective } from '../restrict-keyword-validator';

@Component({
  selector: 'app-careers',
  templateUrl: './careers.component.html',
  styleUrls: ['./careers.component.css']
})
export class CareersComponent implements OnInit {
  careerForm: FormGroup;
  careerData: Career;
  message: string;
  status: string;
  ssnVal: string;

  constructor(
    private fb: FormBuilder,
    private dataService: ApiService,
    private router: Router
  ) {}

  ngOnInit() {
    this.careerForm = this.fb.group({
      ssn: [''],
      position: [''],
      firstname: [''],
      lastname: [''],
      contact: [''],
      email: [''],
      address: [''],
      city: [''],
      state: [''],
      zip: [''],
      qualification: [''],
      experience: ['']
    });
  }

  insertCareer(careerForm) {
    this.careerData = {
      ssn: careerForm.value.ssn,
      position: careerForm.value.position,
      firstname: careerForm.value.firstname,
      lastname: careerForm.value.lastname,
      contact: careerForm.value.contact,
      email: careerForm.value.email,
      address: careerForm.value.address,
      city: careerForm.value.city,
      state: careerForm.value.state,
      zip: careerForm.value.zip,
      qualification: careerForm.value.qualification,
      experience: careerForm.value.experience
    };

    this.dataService
      .addApplicant(this.careerData)
      .pipe(first())
      .subscribe(
        data => {
          const redirect = this.dataService.redirectUrl
            ? this.dataService.redirectUrl
            : '/careers';
          this.router.navigate([redirect]);
          this.careerForm.reset();
          this.status = data.success;
          this.message = 'Application ' + data.msg;
        },
        error => {
          this.router.navigate(['/careers']);
          this.careerForm.reset();
          this.message = 'Some Error Occurred.';
        }
      );
  }

  onBlurSSN(value: string) {
    this.ssnVal = this.careerForm.get('ssn').value;
    this.careerForm.get('ssn').setValue(this.secureSSNFormat(value));
  }

  secureSSNFormat(inputVal) {
    var retVal = inputVal;
    if (inputVal == '') return (retVal = '');
    // if (topFrame.cnfParam("3413") == "MFF") {
    if (inputVal.length == 9) retVal = 'XXX-XX-' + inputVal.substr(5, 4);
    else alert('enter valid ssn');
    // }
    return retVal;
  }

  ValidSSNOnMasking(CorrectSSN, ValueIn) {
    var OriginalValue = CorrectSSN;
    return (
      ValueIn.substr(0, 3) +
      '-' +
      ValueIn.substr(3, 2) +
      '-' +
      ValueIn.substr(5)
    );
  }

  closeMessage() {
    this.message = null;
  }
}
