<footer class="footer">
  <div class="container bottom_border">
    <div class="row">
      <div class="col-lg-4 col-md-6 col-sm-6 col">
        <h5 class="headin5_amrc col_white_amrc pt2">About Us</h5>
        <!--headin5_amrc-->
        <p class="mb10">
          We are a Class A Professional <br />
          Home Care Agency that is locally <br />
          owned and operated; and serving the <br />
          Northern Virginia communities.
        </p>
        <ul class="footer-social">
          <li>
            <a
              class="facebook hb-xs-margin"
              href="https://www.facebook.com/courtesy.healthcare.9"
              target="_blank"
            >
              <span class="hb hb-xs spin hb-facebook">
                <i class="fab fa-facebook-f"></i>
              </span>
            </a>
          </li>
          <li>
            <a
              class="twitter hb-xs-margin"
              href="https://twitter.com/CourtesyHs"
              target="_blank"
            >
              <span class="hb hb-xs spin hb-twitter">
                <i class="fab fa-twitter"></i>
              </span>
            </a>
          </li>
          <li>
            <a
              class="instagram hb-xs-margin"
              href="https://www.instagram.com/courtesyhealthcareservices/"
              target="_blank"
            >
              <span class="hb hb-xs spin hb-instagram">
                <i class="fab fa-instagram"></i>
              </span>
            </a>
          </li>
          <li>
            <a
              class="linkedin hb-xs-margin"
              href="https://www.linkedin.com/in/courtesy-healthcare-service/"
              target="_blank"
            >
              <span class="hb hb-xs spin hb-linkedin">
                <i class="fab fa-linkedin-in"></i>
              </span>
            </a>
          </li>
        </ul>
      </div>
      <div class="col-lg-4 col-md-6 col-sm-6">
        <h5 class="headin5_amrc col_white_amrc pt2">Quick links</h5>
        <!--headin5_amrc-->
        <ul class="footer_ul_amrc">
          <li>
            <a href="index.html"
              ><i class="fas fa-long-arrow-alt-right"></i>Home</a
            >
          </li>
          <li>
            <a [routerLink]="['/covid']"
              ><i class="fas fa-long-arrow-alt-right"></i>Covid 19</a
            >
          </li>
          <li>
            <a [routerLink]="['/personal-care']"
              ><i class="fas fa-long-arrow-alt-right"></i>Personal Care</a
            >
          </li>
          <li>
            <a [routerLink]="['/skilled-care']"
              ><i class="fas fa-long-arrow-alt-right"></i>Skilled Care</a
            >
          </li>
          <li>
            <a [routerLink]="['/about']"
              ><i class="fas fa-long-arrow-alt-right"></i>About Us</a
            >
          </li>
          <li>
            <a [routerLink]="['/our-process']"
              ><i class="fas fa-long-arrow-alt-right"></i>Our Process</a
            >
          </li>
        </ul>
        <!--footer_ul_amrc ends here-->
      </div>
      <div class="col-lg-4 col-md-6 col-sm-6 col">
        <h5 class="headin5_amrc col_white_amrc pt2">Contact us</h5>
        <!--headin5_amrc ends here-->
        <ul class="footer_ul2_amrc">
          <li>
            <p>
              8850 Richmond Hwy. Ste 207A Alexandria, VA 22309
            </p>
          </li>
          <li>
            <p>
              8720 Georgia Avenue, Suite 704. Silver Spring, MD 20910
            </p>
          </li>
          <li>
            <p>
              P: (571) 428-2398 or (571) 361-3076 or (301) 328-5704
            </p>
          </li>
          <li>
            <p>
              F: (571) 428-2399 or (301) 328-5834
            </p>
          </li>
          <li>
            <p>
              E: Info@courtesyhs.com
            </p>
          </li>
        </ul>
        <!--footer_ul2_amrc ends here-->
      </div>
    </div>
  </div>
  <div class="container">
    <p class="copyright text-center" style="margin-top: 0%;">
      All Rights Reserved. &copy; 2020 <a href="#">Courtesy</a> Health Care
      Services
    </p>
  </div>
</footer>
