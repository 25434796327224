import { Component, OnInit, ViewEncapsulation } from '@angular/core';

import { ApiService } from '../api.service';
import { Router } from '@angular/router';
import { first } from 'rxjs/operators';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {
  testimonials = [];

  constructor(private dataService: ApiService, private router: Router) {
    encapsulation: ViewEncapsulation.None;
  }

  ngOnInit(): void {
    this.dataService
      .getTestimonials()
      .pipe(first())
      .subscribe(data => {
        console.log(data);
        this.testimonials = data;
      });
  }
}
