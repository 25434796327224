import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators, NgForm } from '@angular/forms';
import { first } from 'rxjs/operators';
import { Router } from '@angular/router';
import { ApiService } from '../api.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  loginForm: FormGroup;
  message: string;
  constructor(
    private fb: FormBuilder,
    private dataService: ApiService,
    private router: Router
  ) {
    this.loginForm = this.fb.group({
      username: ['', Validators.required],
      password: ['', [Validators.required, Validators.minLength(8)]]
    });
  }

  ngOnInit() {}

  postdata(login) {
    this.dataService
      .userlogin(login.value.username, login.value.password)
      .pipe(first())
      .subscribe(
        data => {
          if (data.role === 'ADMIN') {
            const adminRedirect = this.dataService.redirectUrl
              ? this.dataService.redirectUrl
              : '/admin';
            this.router.navigate([adminRedirect]);
          } else {
            const employeeRedirect = this.dataService.redirectUrl
              ? this.dataService.redirectUrl
              : '/employee-details';
            this.router.navigate([employeeRedirect]);
          }
          this.reset();
        },
        error => {
          this.router.navigate(['/login']);
          this.reset();
          this.message = 'User name or password is incorrect';
        }
      );
  }

  get username() {
    return this.loginForm.get('username');
  }

  get password() {
    return this.loginForm.get('password');
  }

  reset() {
    this.message = null;
    this.loginForm.reset();
  }
}
